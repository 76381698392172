import React from 'react';
import styled from 'styled-components';

import { getLocalizedTexts } from '../../Locales';
import { colors } from '../../tokens/colors/colors';
import { font } from '../text';

export function RewindArrows() {
    const [fill, setFill] = React.useState('none');
    const [isHovered, setIsHovered] = React.useState(false);
    const [strokeFill, setStrokeFill] = React.useState(colors.background.tertiaryDisabled);
    const texts = getLocalizedTexts().productDiscovery;
    React.useEffect(() => {
        if (isHovered) {
            setFill(colors.border.secondaryPressed);
            setStrokeFill(colors.border.secondaryPressed);
        } else {
            setFill('none');
            setStrokeFill(colors.background.tertiaryDisabled);
        }
    }, [isHovered]);
    return (
        <TooltipWrapper {...{ isHovered }} onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="rewind-arrows">
                    <path
                        id="Vector"
                        d="M9.09888 9.78142L9.11494 10.4667L9.09888 11.152C9.07486 12.1767 8.99698 13.1976 8.86546 14.2004C8.82915 14.4772 8.54775 14.6396 8.30527 14.5535L7.8442 14.3898C5.42377 13.5301 3.15538 12.2912 1.12393 10.7193C0.958689 10.5915 0.958689 10.342 1.12393 10.2141C3.15538 8.64228 5.42377 7.40334 7.8442 6.54369L8.30527 6.37993C8.54775 6.29382 8.82915 6.45621 8.86546 6.73301C8.99698 7.73588 9.07486 8.75671 9.09888 9.78142ZM10.8327 10.4667L10.9419 10.2955C10.9598 10.2674 10.984 10.2397 11.017 10.2141C13.0485 8.64228 15.3169 7.40334 17.7373 6.54369L18.1984 6.37993L17.8654 5.44248L18.1984 6.37993C18.4408 6.29382 18.7222 6.45621 18.7586 6.73301C19.0805 9.18773 19.0805 11.7457 18.7586 14.2004C18.7222 14.4772 18.4408 14.6396 18.1984 14.5535L17.7373 14.3898C15.3169 13.5301 13.0485 12.2912 11.017 10.7193C10.984 10.6937 10.9598 10.6661 10.9419 10.638L10.8327 10.4667Z"
                        fill={fill}
                        stroke={strokeFill}
                        strokeWidth="2"
                    />
                </g>
            </svg>
            <Tooltip className="tooltip">{texts.showProductsToolTip}</Tooltip>
        </TooltipWrapper>
    );
}

const TooltipWrapper = styled.div<{ isHovered: boolean }>`
    position: relative;
    display: inline-block;
    height: 20px;
    &:hover .tooltip {
        ${({ isHovered }) =>
            isHovered
                ? `
        visibility: visible;
        opacity: 1;
        `
                : ``};
`;

const Tooltip = styled.div`
    position: absolute;
    top: 50%;
    left: 28px;
    transform: translateY(-50%);
    background-color: ${colors.border.secondaryPressed};
    color: ${colors.content.primary};
    padding: 6px 10px;
    border-radius: 4px;
    font-family: ${font.ambitSemiBold};
    font-size: 14px;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s;

    &::after {
        content: none;
    }
`;