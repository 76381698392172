import * as React from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import moment from 'moment';

import {
    UserProductDiscoveryConversationHistoryItem,
    UserProductDiscoveryMessageWithProducts,
} from '../../api/graphql/fragments/productDiscovery';
import { ApolloClient } from '../../api/graphql/client';
import { deleteConversationHistoryMutation } from '../../api/graphql/mutations/deleteHistoryConversation';
import {
    userProductDiscoveryConversationsQuery,
    UserProductDiscoveryConversationsQueryResponse,
    UserProductDiscoveryConversationsQueryVariables,
    userProductDiscoveryMessagesWithProductsQuery,
    UserProductDiscoveryMessagesWithProductsQueryResponse,
    UserProductDiscoveryMessagesWithProductsQueryVariables,
} from '../../api/graphql/queries/productDiscoveryConversations';
import { getLocalizedTexts } from '../../Locales';
import { capitalizeFirstLetter, isMobileDevice } from '../../style/utils';
import { EnrichedProductDiscoveryConversationMessage } from './conversationMessages';
import { Feature, useHasFeature } from '../features/buildFeatureMap';

// We set a limit for conversations date for backwards compatibility since we didn't store the suggested products in the first conversations
const DATE_LIMIT_FOR_FETCHING_CONVERSATIONS = new Date('2024-12-02T10:00:00Z');

export function useConversationHistoryItems(
    lastConversationHistoryItem: UserProductDiscoveryConversationHistoryItem | undefined
): {
    conversationHistoryItemsByDate: ConversationHistoryItemsByDate | undefined;
    setConversationHistoryItemsByDate: (value: ConversationHistoryItemsByDate) => void;
    fetchMore: () => void;
    checkCanFetchMore: () => boolean;
    isFetchingMore: boolean;
} {
    const queryResponse = useQuery<
        UserProductDiscoveryConversationsQueryResponse,
        UserProductDiscoveryConversationsQueryVariables
    >(userProductDiscoveryConversationsQuery, {
        variables: {},
        fetchPolicy: 'cache-and-network',
    });
    const [conversationHistoryItemsByDate, setConversationHistoryItemsByDate] = React.useState<
        ConversationHistoryItemsByDate | undefined
    >(undefined);
    React.useEffect(() => {
        const conversationHistoryItems = queryResponse.data?.user?.productDiscoveryConversations?.items || undefined;
        const filteredConversationHistoryItems = conversationHistoryItems?.filter(
            ({ createdAt }) => new Date(createdAt) > DATE_LIMIT_FOR_FETCHING_CONVERSATIONS
        );
        setConversationHistoryItemsByDate(groupConversationHistoryItemsByDate(filteredConversationHistoryItems || []));
    }, [queryResponse]);
    useUpdateConversationHistory({
        lastConversationHistoryItem,
        setConversationHistoryItemsByDate,
    });
    const [isFetchingMore, setIsFetchingMore] = React.useState(false);
    // We use the `fetchMore` function to fetch more conversations when the user scrolls to the bottom of the list
    const fetchMore = React.useCallback(async () => {
        const nextToken = queryResponse.data?.user?.productDiscoveryConversations?.nextToken;
        if (!nextToken || isFetchingMore) return;
        const nextTokenObject = JSON.parse(nextToken);
        if (!nextTokenObject?.createdAt) return;
        const nextTokenDate = new Date(nextTokenObject.createdAt);
        if (nextTokenDate < DATE_LIMIT_FOR_FETCHING_CONVERSATIONS) return;
        setIsFetchingMore(true);
        // It executes a query with the exact same shape and variables as the original query, and relies on `nextToken` to know when the pagination should resume
        await queryResponse.fetchMore<
            UserProductDiscoveryConversationsQueryResponse,
            UserProductDiscoveryConversationsQueryVariables,
            any
        >({
            variables: { nextToken },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                setIsFetchingMore(false);
                const previousItems = previousResult?.user?.productDiscoveryConversations?.items ?? [];
                const previousItemsIds = new Set(previousItems.map(({ conversationId }) => conversationId));
                const fetchMoreItems = fetchMoreResult?.user?.productDiscoveryConversations?.items ?? [];
                const filteredFetchMoreItems = fetchMoreItems.filter(
                    ({ conversationId }) => !previousItemsIds.has(conversationId)
                );
                return {
                    __typename: 'Query',
                    user: {
                        __typename: 'User',
                        productDiscoveryConversations: {
                            __typename: 'UserProductDiscoveryConversationList',
                            items: [...previousItems, ...filteredFetchMoreItems],
                            nextToken: fetchMoreResult?.user?.productDiscoveryConversations.nextToken ?? null,
                        },
                    },
                };
            },
        });
    }, [queryResponse]);
    const checkCanFetchMore = React.useCallback(() => {
        const nextToken = queryResponse.data?.user?.productDiscoveryConversations?.nextToken;
        return !!nextToken && !isFetchingMore;
    }, [queryResponse]);
    return {
        conversationHistoryItemsByDate,
        setConversationHistoryItemsByDate,
        fetchMore,
        checkCanFetchMore,
        isFetchingMore,
    };
}

export type ConversationHistoryItemsByDate = {
    date: string;
    conversations: UserProductDiscoveryConversationHistoryItem[];
}[];

function groupConversationHistoryItemsByDate(
    conversationsHistoryItems: UserProductDiscoveryConversationHistoryItem[]
): ConversationHistoryItemsByDate {
    const texts = getLocalizedTexts().productDiscovery.conversationHistory.dateSections;
    const conversationHistoryItemsByDateMap: {
        [timestamp: number]: { date: string; conversationHistoryItems: UserProductDiscoveryConversationHistoryItem[] };
    } = {};
    const today = moment();
    const yesterday = moment().subtract(1, 'days');
    const lastMonth = moment().subtract(30, 'days');
    for (const item of conversationsHistoryItems) {
        const itemDate = moment(item.createdAt);
        if (itemDate.isSame(today, 'day')) {
            const timestamp = today.valueOf();
            conversationHistoryItemsByDateMap[timestamp] = {
                date: texts.today,
                conversationHistoryItems: [
                    ...(conversationHistoryItemsByDateMap[timestamp]?.conversationHistoryItems || []),
                    item,
                ],
            };
        } else if (itemDate.isSame(yesterday, 'day')) {
            const timestamp = yesterday.valueOf();
            conversationHistoryItemsByDateMap[timestamp] = {
                date: texts.yesterday,
                conversationHistoryItems: [
                    ...(conversationHistoryItemsByDateMap[timestamp]?.conversationHistoryItems || []),
                    item,
                ],
            };
        } else if (itemDate.isAfter(lastMonth)) {
            const timestamp = lastMonth.valueOf();
            conversationHistoryItemsByDateMap[timestamp] = {
                date: texts.lastMonth,
                conversationHistoryItems: [
                    ...(conversationHistoryItemsByDateMap[timestamp]?.conversationHistoryItems || []),
                    item,
                ],
            };
        } else if (itemDate.isSame(today, 'year')) {
            const timestamp = itemDate.startOf('month').valueOf();
            const monthName = capitalizeFirstLetter(itemDate.format('MMMM'));
            conversationHistoryItemsByDateMap[timestamp] = {
                date: monthName,
                conversationHistoryItems: [
                    ...(conversationHistoryItemsByDateMap[timestamp]?.conversationHistoryItems || []),
                    item,
                ],
            };
        } else {
            const timestamp = itemDate.startOf('year').valueOf();
            const year = itemDate.format('YYYY');
            conversationHistoryItemsByDateMap[timestamp] = {
                date: year,
                conversationHistoryItems: [
                    ...(conversationHistoryItemsByDateMap[timestamp]?.conversationHistoryItems || []),
                    item,
                ],
            };
        }
    }
    const conversationHistoryItemsByDate: ConversationHistoryItemsByDate = sortConversationHistoryItemsByDate(
        conversationHistoryItemsByDateMap
    );
    return conversationHistoryItemsByDate;
}

function sortConversationHistoryItemsByDate(conversationHistoryItemsByDateMap: {
    [timestamp: number]: {
        date: string;
        conversationHistoryItems: UserProductDiscoveryConversationHistoryItem[];
    };
}): ConversationHistoryItemsByDate {
    const sortedConversationHistoryItemsByDate = Object.entries(conversationHistoryItemsByDateMap)
        .map(([timestamp, { date, conversationHistoryItems }]) => ({
            timestamp: parseInt(timestamp),
            date,
            conversationHistoryItems,
        }))
        .sort((a, b) => b.timestamp - a.timestamp);
    return sortedConversationHistoryItemsByDate.map(({ date, conversationHistoryItems }) => ({
        date,
        conversations: conversationHistoryItems,
    }));
}

function useUpdateConversationHistory({
    lastConversationHistoryItem,
    setConversationHistoryItemsByDate,
}: {
    lastConversationHistoryItem: UserProductDiscoveryConversationHistoryItem | undefined;
    setConversationHistoryItemsByDate: React.Dispatch<React.SetStateAction<ConversationHistoryItemsByDate | undefined>>;
}) {
    const texts = getLocalizedTexts().productDiscovery.conversationHistory.dateSections;
    React.useEffect(() => {
        if (!lastConversationHistoryItem) return;
        setConversationHistoryItemsByDate((previousConversationHistoryItemsByDate) => {
            if (!previousConversationHistoryItemsByDate)
                return [{ date: texts.today, conversations: [lastConversationHistoryItem] }];
            const todayGroupIndex = previousConversationHistoryItemsByDate.findIndex((group) => group.date === texts.today);
            if (todayGroupIndex === -1)
                return [
                    { date: texts.today, conversations: [lastConversationHistoryItem] },
                    ...previousConversationHistoryItemsByDate,
                ];
            const todayGroup = previousConversationHistoryItemsByDate[todayGroupIndex];
            const existingItemIndex = todayGroup.conversations.findIndex(
                (item) => item.conversationId === lastConversationHistoryItem.conversationId
            );
            // If the conversation is not in the list, we add it at the beginning
            if (existingItemIndex === -1) {
                const updatedTodayGroup = {
                    date: texts.today,
                    conversations: [lastConversationHistoryItem, ...todayGroup.conversations],
                };
                return [
                    updatedTodayGroup,
                    ...previousConversationHistoryItemsByDate.filter((_, index) => index !== todayGroupIndex),
                ];
            }
            // If the conversation is already in the list, we update it
            const updatedTodayGroup = {
                date: texts.today,
                conversations: todayGroup.conversations.map((item) =>
                    item.conversationId === lastConversationHistoryItem.conversationId ? lastConversationHistoryItem : item
                ),
            };
            return [
                updatedTodayGroup,
                ...previousConversationHistoryItemsByDate.filter((_, index) => index !== todayGroupIndex),
            ];
        });
    }, [lastConversationHistoryItem]);
}

export async function updateProductDiscoveryConversationsQueryInCache({
    apolloClient,
    conversationId,
    createdAt,
    generatedConversationTitle,
    isSandboxEnvironment,
}: {
    apolloClient: ApolloClient;
    conversationId: string;
    createdAt: string;
    generatedConversationTitle?: string;
    isSandboxEnvironment: boolean;
}) {
    let valueInCache = undefined;
    try {
        valueInCache = apolloClient.readQuery<UserProductDiscoveryConversationsQueryResponse>({
            query: userProductDiscoveryConversationsQuery,
            variables: {},
        });
    } catch (error) {
        if (isSandboxEnvironment) console.error('Error while fetching messages with products in cache', error);
    }
    let items = valueInCache?.user?.productDiscoveryConversations?.items || [];
    let lastConversation = items.length > 0 ? items[items.length - 1] : undefined;
    if (lastConversation?.conversationId === conversationId && generatedConversationTitle) {
        lastConversation.conversationTitle = generatedConversationTitle;
        items[items.length - 1] = lastConversation;
        apolloClient.writeQuery<UserProductDiscoveryConversationsQueryResponse>({
            query: userProductDiscoveryMessagesWithProductsQuery,
            variables: {},
            data: {
                __typename: 'Query',
                user: {
                    __typename: 'User',
                    productDiscoveryConversations: {
                        __typename: 'UserProductDiscoveryConversationList',
                        items,
                        nextToken: valueInCache?.user?.productDiscoveryConversations?.nextToken || null,
                    },
                },
            },
        });
        return;
    }
    const newConversationTitlePlaceholder = getLocalizedTexts().productDiscovery.newConversationTitlePlaceholder;
    const newConversationItem: UserProductDiscoveryConversationHistoryItem = {
        __typename: 'UserProductDiscoveryConversationHistoryItem',
        conversationId,
        createdAt,
        conversationTitle: generatedConversationTitle ?? newConversationTitlePlaceholder,
    };
    apolloClient.writeQuery<UserProductDiscoveryConversationsQueryResponse>({
        query: userProductDiscoveryMessagesWithProductsQuery,
        variables: {},
        data: {
            __typename: 'Query',
            user: {
                __typename: 'User',
                productDiscoveryConversations: {
                    __typename: 'UserProductDiscoveryConversationList',
                    items: [newConversationItem, ...(valueInCache?.user?.productDiscoveryConversations?.items || [])],
                    nextToken: valueInCache?.user?.productDiscoveryConversations?.nextToken || null,
                },
            },
        },
    });
}

export function updateProductDiscoveryConversationMessageWithProductsListInCache(
    client: ApolloClient,
    conversationId: string,
    isSandboxEnvironment: boolean,
    productDiscoveryConversationMessageWithProducts: UserProductDiscoveryMessageWithProducts[]
) {
    let valueInCache = undefined;
    try {
        valueInCache = client.readQuery<UserProductDiscoveryMessagesWithProductsQueryResponse>({
            query: userProductDiscoveryMessagesWithProductsQuery,
            variables: { conversationId },
        });
    } catch (error) {
        if (isSandboxEnvironment) console.error('Error while fetching messages with products in cache', error);
    }
    client.writeQuery<UserProductDiscoveryMessagesWithProductsQueryResponse>({
        query: userProductDiscoveryMessagesWithProductsQuery,
        variables: { conversationId },
        data: {
            __typename: 'Query',
            user: {
                __typename: 'User',
                productDiscoveryConversationWithProducts: {
                    __typename: 'UserProductDiscoveryMessageWithProductsList',
                    items: productDiscoveryConversationMessageWithProducts,
                    lastFetchedAt: valueInCache?.user?.productDiscoveryConversationWithProducts?.lastFetchedAt || null,
                    nextToken: null,
                },
            },
        },
    });
}

export function useFetchMessagesOnConversationReopen({
    selectedConversationHistoryItemId,
    resetConversation,
    inputTextRef,
    setFailedToFetchConversation,
    setIsAssistantThinking,
}: {
    selectedConversationHistoryItemId: string | undefined;
    resetConversation: ({
        conversationToReopen,
    }: {
        conversationToReopen?: {
            conversationId: string;
            messages: EnrichedProductDiscoveryConversationMessage[];
        };
    }) => void;
    inputTextRef: React.RefObject<HTMLTextAreaElement | null>;
    setFailedToFetchConversation: (value: boolean) => void;
    setIsAssistantThinking: (value: boolean) => void;
}) {
    const apolloClient = useApolloClient();
    React.useEffect(() => {
        // We fetch the messages for the selected conversation history item and reset the conversation with the fetched messages
        async function getProductDiscoveryMessagesWithProductsFromPastConversation() {
            if (!apolloClient || !selectedConversationHistoryItemId) return;
            // We want to show the placeholders while we are fetching the messages and products
            setIsAssistantThinking(true);
            const messages = await getProductDiscoveryMessagesWithProducts(apolloClient, selectedConversationHistoryItemId);
            if (!messages?.length) {
                setFailedToFetchConversation(true);
                return;
            }
            resetConversation({ conversationToReopen: { conversationId: selectedConversationHistoryItemId, messages } });
            setIsAssistantThinking(false);
            if (!isMobileDevice) inputTextRef.current?.focus();
        }
        getProductDiscoveryMessagesWithProductsFromPastConversation();
    }, [apolloClient, selectedConversationHistoryItemId]);
}

const USER_MESSAGES_WITH_PRODUCTS_QUERY_REFETCH_FREQUENCY_IN_MS = 12 * 60 * 60 * 1000; // 12 hours

async function getProductDiscoveryMessagesWithProducts(
    apolloClient: ApolloClient,
    conversationId: string
): Promise<EnrichedProductDiscoveryConversationMessage[] | undefined> {
    let queryResponse = await apolloClient.query<
        UserProductDiscoveryMessagesWithProductsQueryResponse,
        UserProductDiscoveryMessagesWithProductsQueryVariables
    >({
        query: userProductDiscoveryMessagesWithProductsQuery,
        variables: { conversationId },
        fetchPolicy: 'cache-first',
    });
    const lastFetchedAt = queryResponse.data?.user?.productDiscoveryConversationWithProducts?.lastFetchedAt;
    if (!lastFetchedAt || lastFetchedAt < Date.now() - USER_MESSAGES_WITH_PRODUCTS_QUERY_REFETCH_FREQUENCY_IN_MS) {
        queryResponse = await apolloClient.query<
            UserProductDiscoveryMessagesWithProductsQueryResponse,
            UserProductDiscoveryMessagesWithProductsQueryVariables
        >({
            query: userProductDiscoveryMessagesWithProductsQuery,
            variables: { conversationId },
            fetchPolicy: 'network-only',
        });
    }
    const productDiscoveryConversationWithProducts =
        queryResponse.data?.user?.productDiscoveryConversationWithProducts?.items || undefined;
    return productDiscoveryConversationWithProducts.map((item, index) => ({
        ...item,
        messageIndex:
            item.messageId && item.messageId.split('|').length === 2 ? Number(item.messageId.split('|')[1]) : index,
        merchantProductOffers: item.merchantProductOffers || undefined,
    }));
}

const SHOULD_SHOW_CONVERSATION_HISTORY_STORAGE_KEY = 'shouldShowConversationHistory';

export function useShouldShowConversationHistory() {
    const [shouldShowConversationHistory, setShouldShowConversationHistoryState] = React.useState(
        localStorage.getItem(SHOULD_SHOW_CONVERSATION_HISTORY_STORAGE_KEY) === 'true'
    );
    const setShouldShowConversationHistory = React.useCallback((value: boolean) => {
        setShouldShowConversationHistoryState(value);
        localStorage.setItem(SHOULD_SHOW_CONVERSATION_HISTORY_STORAGE_KEY, value.toString());
    }, []);
    return { shouldShowConversationHistory, setShouldShowConversationHistory };
}

export function useDeleteConversationHistory(
    setConversationHistoryItemsByDate: (value: ConversationHistoryItemsByDate) => void
): () => Promise<void> {
    const isSandboxEnvironment = !!useHasFeature(Feature.useProductDiscoverySandboxEnvironment);
    const apolloClient = useApolloClient();
    const deleteConversationHistory = async () => {
        try {
            if (!apolloClient) return;
            await apolloClient.mutate({
                mutation: deleteConversationHistoryMutation,
                fetchPolicy: 'no-cache',
            });
            setConversationHistoryItemsByDate([]);
            clearProductDiscoveryConversationsFromCache({
                apolloClient,
                isSandboxEnvironment: isSandboxEnvironment,
            });
        } catch (error) {
            if (isSandboxEnvironment) console.error(error);
        }
    };
    return deleteConversationHistory;
}

export function clearProductDiscoveryConversationsFromCache({
    apolloClient,
    isSandboxEnvironment,
}: {
    apolloClient: ApolloClient;
    isSandboxEnvironment: boolean;
}) {
    try {
        apolloClient.writeQuery({
            query: userProductDiscoveryConversationsQuery,
            variables: {},
            data: {
                __typename: 'Query',
                user: {
                    __typename: 'User',
                    productDiscoveryConversations: {
                        __typename: 'UserProductDiscoveryConversationList',
                        items: [],
                        nextToken: null,
                    },
                },
            },
        });

        if (isSandboxEnvironment) console.log('Successfully cleared product discovery conversations from cache.');
    } catch (error) {
        if (isSandboxEnvironment) console.error('Error while clearing conversations from cache:', error);
    }
}
