import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/react-hooks';

import { EnrichedProductDiscoveryConversationMessage } from '../../../lib/productDiscovery/conversationMessages';
import { logUserEventUtil } from '../../../lib/events/userEvents';
import { deviceWidthMeasurements } from '../../../style/size';
import { font } from '../../../style/text';
import { colors } from '../../../tokens/colors/colors';
import { CLICKABLE_ELEMENT_CSS_MIXIN, NO_SCROLLBAR_CSS_MIXIN } from '../../../style/styleConstants';
import GradientText from '../../../components/common/GradientText';
import { getLocalizedTexts } from '../../../Locales';
import { MerchantProductOfferCard, MerchantProductOfferPlaceholderCard } from '../common/MerchantProductOfferCard';
import { InputBar } from '../common/InputBar';
import { ScrollToBottomButton } from '../common/ScrollToBottomButton';
import { useViewportSize } from '../../../style/utils';
import { MoreResultsSpinningWheel } from '../../../components/common/SpinningWheel';
import {
    FeedbackButton,
    FeedbackButtonIcon,
    FeedbackButtonText,
    InputBarAndFeedbackContainer,
} from '../common/FeedbackButton';
import { HeaderImage } from '../common/Header';
import { HeaderImageContainer } from './DesktopHeader';
import { RewindArrows } from '../../../style/reactSvg/RewindArrows';
import { JokoAiBetaTitle } from '../common/JokoAiBetaTitle';
import { useLogViewedConversationInterfaceOnJokoAi } from '../../../lib/productDiscovery/events';
import { useMerchantIdToMerchantNameMap, useHandleScroll } from '../../../lib/productDiscovery/displayLogic';

const editIcon = '/assets/images/icons/edit.svg';
const crossCircle = '/assets/images/icons/cross-circle.svg';
const logo = '/assets/images/logos/lightning-round.svg';
const megaphoneIcon = '/assets/images/icons/megaphone.svg';
const MAX_NUMBER_OF_VISIBLE_MERCHANT_PRODUCT_OFFERS_WITHOUT_SCROLLING = 8;

function DesktopMessagesSection({
    messages,
    messagesListRef,
    conversationId,
    selectedConversationHistoryItemId,
    inputText,
    setInputText,
    inputTextRef,
    setIsInputBarFocused,
    sendMessage,
    isAssistantThinking,
    setBottomPositionScrollButton,
    fetchMoreProductSuggestions,
    isFetchingMoreProductSuggestions,
    isFromSerpEmbedding,
    resetConversation,
    setShouldShowFeedbackSection,
}: {
    messages: EnrichedProductDiscoveryConversationMessage[];
    messagesListRef: React.MutableRefObject<HTMLDivElement | null>;
    conversationId: string;
    selectedConversationHistoryItemId: string | undefined;
    inputText: string;
    setInputText: (value: string) => void;
    inputTextRef: React.RefObject<HTMLTextAreaElement>;
    setIsInputBarFocused: (value: boolean) => void;
    sendMessage: (suggestedMessage?: string) => void;
    resetConversation: ({
        conversationToReopen,
    }: {
        conversationToReopen?: {
            conversationId: string;
            messages: EnrichedProductDiscoveryConversationMessage[];
        };
    }) => void;
    isAssistantThinking: boolean;
    setBottomPositionScrollButton: (value: number) => void;
    fetchMoreProductSuggestions: (messageId: string, currentQueryScrollOffsets: number[] | undefined) => void;
    isFetchingMoreProductSuggestions: boolean;
    isFromSerpEmbedding: boolean;
    setShouldShowFeedbackSection: (value: boolean) => void;
}) {
    const apolloClient = useApolloClient();
    useLogViewedConversationInterfaceOnJokoAi({
        conversationId,
        selectedConversationHistoryItemId,
        isFromSerpEmbedding,
    });
    const productSuggestionsListRef = React.useRef<HTMLDivElement | null>(null);
    const merchantIdToMerchantNameMap = useMerchantIdToMerchantNameMap({ messages });
    const { selectedMessageIndexForProductOffers, setSelectedMessageIndexForProductOffers } =
        useSelectedMessageIndexForProductOffers(messages, isAssistantThinking, isFetchingMoreProductSuggestions);
    const { isAtBottom: isMessagesListAtBottom, onScroll: onScrollMessagesList } = useHandleScroll({
        listRef: messagesListRef,
        listContent: messages,
        shouldAutoScrollToBottom: true,
    });
    const { shouldDisplayNoMoreResultsText, onScrollCallback } = getScrollCallback({
        messages,
        conversationId,
        selectedMessageIndexForProductOffers,
        fetchMoreProductSuggestions,
    });
    const { isAtBottom: isProductSuggestionsListAtBottom, onScroll: onScrollProductSuggestionsList } = useHandleScroll({
        listRef: productSuggestionsListRef,
        listContent: selectedMessageIndexForProductOffers,
        onScrollCallback,
    });
    const { width: viewportWidth } = useViewportSize();
    const renderTextMessage = getRenderTextMessage({
        selectedMessageIndexForProductOffers,
        setSelectedMessageIndexForProductOffers,
    });
    const texts = getLocalizedTexts();
    return (
        <MessagesSectionContainer>
            <LeftContainer>
                <MessagesHeader>
                    <JokoAiBetaTitle />
                    <HeaderImageContainer>
                        <HeaderImage
                            src={editIcon}
                            draggable={false}
                            onClick={() => {
                                resetConversation({});
                                inputTextRef.current?.focus();
                                logUserEventUtil(apolloClient, {
                                    type: 'clickedNewConversationButtonOnJokoAi',
                                });
                            }}
                        />
                    </HeaderImageContainer>
                </MessagesHeader>
                <MessagesTextContainer ref={messagesListRef} onScroll={onScrollMessagesList}>
                    <MessagesTextInnerContainer>
                        {messages.map((message) =>
                            renderTextMessage({
                                item: message,
                                numberOfMessages: messages.length,
                            })
                        )}
                    </MessagesTextInnerContainer>
                    {!isMessagesListAtBottom ? (
                        <ScrollToBottomButton
                            {...{
                                listRef: messagesListRef,
                                bottomPositionScrollButton: 116,
                            }}
                        />
                    ) : null}
                </MessagesTextContainer>
                <InputBarAndFeedbackContainer>
                    <InputBar
                        {...{
                            inputText,
                            setInputText,
                            inputTextRef,
                            setIsInputBarFocused,
                            sendMessage,
                            isAssistantThinking,
                            setBottomPositionScrollButton,
                        }}
                    />
                    <FeedbackButton
                        onClick={() => {
                            setShouldShowFeedbackSection(true);
                            logUserEventUtil(apolloClient, {
                                type: 'clickedFeedbackButtonOnJokoAi',
                                payload: { conversationId },
                            });
                        }}>
                        <FeedbackButtonIcon src={megaphoneIcon} />
                        <FeedbackButtonText>{texts.productDiscovery.feedback.buttonTitle}</FeedbackButtonText>
                    </FeedbackButton>
                </InputBarAndFeedbackContainer>
            </LeftContainer>
            <MessagesProductSuggestionsContainer
                {...{ isPlaceholder: selectedMessageIndexForProductOffers === undefined }}
                ref={productSuggestionsListRef}
                onScroll={onScrollProductSuggestionsList}>
                {selectedMessageIndexForProductOffers ? (
                    <>
                        <SuggestedMerchantProductOffersContainer>
                            {messages[selectedMessageIndexForProductOffers]?.merchantProductOffers?.map((offer) => (
                                <MerchantProductOfferCard
                                    key={offer.merchantProductOfferId}
                                    {...{
                                        offer,
                                        merchantIdToMerchantNameMap,
                                        conversationId,
                                        messageId: `${conversationId}|${selectedMessageIndexForProductOffers}`,
                                    }}
                                />
                            ))}
                        </SuggestedMerchantProductOffersContainer>
                        {!isProductSuggestionsListAtBottom &&
                        messages[selectedMessageIndexForProductOffers]?.merchantProductOffers?.length ? (
                            <ScrollToBottomButton
                                {...{
                                    listRef: productSuggestionsListRef,
                                    bottomPositionScrollButton: 10,
                                }}
                            />
                        ) : null}
                        {isFetchingMoreProductSuggestions ? (
                            <LoadingInProgressContainer>
                                <MoreResultsSpinningWheel size={16} />
                                <LoadingInProgressText>
                                    {texts.productDiscovery.fetchingMoreProductSuggestions}
                                </LoadingInProgressText>
                            </LoadingInProgressContainer>
                        ) : shouldDisplayNoMoreResultsText ? (
                            <LoadingInProgressContainer>
                                <NoMoreResultsText>{texts.productDiscovery.noMoreProductSuggestions}</NoMoreResultsText>
                            </LoadingInProgressContainer>
                        ) : null}
                    </>
                ) : (
                    <SuggestedMerchantProductOffersContainer {...{ isPlaceholder: true }}>
                        <MerchantProductOfferPlaceholderCards {...{ viewportWidth }} />
                    </SuggestedMerchantProductOffersContainer>
                )}
            </MessagesProductSuggestionsContainer>
        </MessagesSectionContainer>
    );
}

export default DesktopMessagesSection;

export function getScrollCallback({
    messages,
    conversationId,
    selectedMessageIndexForProductOffers,
    fetchMoreProductSuggestions,
}: {
    messages: EnrichedProductDiscoveryConversationMessage[];
    conversationId: string;
    selectedMessageIndexForProductOffers: number | undefined;
    fetchMoreProductSuggestions: (messageId: string, currentQueryScrollOffsets: number[] | undefined) => void;
}) {
    const message =
        selectedMessageIndexForProductOffers !== undefined ? messages[selectedMessageIndexForProductOffers] : undefined;
    const areMoreProductsAvailableByScrolling = message?.queryScrollOffsets?.length
        ? message.queryScrollOffsets?.some((offset) => offset !== -1)
        : true;
    const shouldDisplayNoMoreResultsText =
        !areMoreProductsAvailableByScrolling ||
        (selectedMessageIndexForProductOffers &&
            messages[selectedMessageIndexForProductOffers]?.merchantProductOffers?.length &&
            messages[selectedMessageIndexForProductOffers]?.merchantProductOffers?.length <
                MAX_NUMBER_OF_VISIBLE_MERCHANT_PRODUCT_OFFERS_WITHOUT_SCROLLING);
    const onScrollCallback = (isAtBottom: boolean) => {
        if (!isAtBottom) return;
        if (!shouldDisplayNoMoreResultsText && message)
            fetchMoreProductSuggestions(`${conversationId}|${message.messageIndex}`, message?.queryScrollOffsets);
    };
    return { onScrollCallback, shouldDisplayNoMoreResultsText };
}

function useSelectedMessageIndexForProductOffers(
    messages: EnrichedProductDiscoveryConversationMessage[],
    isAssistantThinking: boolean,
    isFetchingMoreProductSuggestions: boolean
) {
    const getInitialSelectedMessageId = () => {
        if (messages.length > 1 && messages[messages.length - 1]?.role === 'assistant') {
            return Number(messages[messages.length - 1].messageIndex);
        }
        return undefined;
    };
    const [selectedMessageIndexForProductOffers, setSelectedMessageIndexForProductOffers] = React.useState<
        number | undefined
    >(getInitialSelectedMessageId());
    React.useEffect(() => {
        if (
            messages.length > 1 &&
            messages[messages.length - 1]?.role === 'assistant' &&
            !isAssistantThinking &&
            !isFetchingMoreProductSuggestions
        )
            setSelectedMessageIndexForProductOffers(Number(messages[messages.length - 1].messageIndex));
        else if (isAssistantThinking) setSelectedMessageIndexForProductOffers(undefined);
    }, [messages, isAssistantThinking]);
    return { selectedMessageIndexForProductOffers, setSelectedMessageIndexForProductOffers };
}

export function getRenderTextMessage({
    selectedMessageIndexForProductOffers,
    setSelectedMessageIndexForProductOffers,
}: {
    selectedMessageIndexForProductOffers?: number | undefined;
    setSelectedMessageIndexForProductOffers?: React.Dispatch<React.SetStateAction<number | undefined>>;
}): React.FC<{ item: EnrichedProductDiscoveryConversationMessage; numberOfMessages: number; isFeedbackMessage?: boolean }> {
    return ({ item: message, numberOfMessages, isFeedbackMessage }) => {
        if (message.role === 'user') return <UserMessage {...{ message }} key={message.messageIndex} />;
        return (
            <AssistantMessage
                {...{
                    message,
                    numberOfMessages,
                    selectedMessageIndexForProductOffers,
                    setSelectedMessageIndexForProductOffers,
                    isFeedbackMessage,
                }}
                key={message.messageIndex}
            />
        );
    };
}

function UserMessage({ message }: { message: EnrichedProductDiscoveryConversationMessage }) {
    return (
        <UserMessageContainer {...{ isFirstMessage: message.messageIndex === 0 }}>
            <MessageUserText>{message.content}</MessageUserText>
        </UserMessageContainer>
    );
}

function AssistantMessage({
    message,
    numberOfMessages,
    selectedMessageIndexForProductOffers,
    setSelectedMessageIndexForProductOffers,
    isFeedbackMessage,
}: {
    message: EnrichedProductDiscoveryConversationMessage;
    numberOfMessages: number;
    selectedMessageIndexForProductOffers?: number | undefined;
    setSelectedMessageIndexForProductOffers?: React.Dispatch<React.SetStateAction<number | undefined>>;
    isFeedbackMessage?: boolean;
}) {
    const texts = getLocalizedTexts();
    return (
        <AssistantMessageContainer {...{ isFeedbackMessage }}>
            <AssistantMessageTextContainer {...{ isFeedbackMessage }}>
                <JokoLogoMessageImage src={logo} />
                {message.content ? (
                    <MessageAssistantText>
                        <FormattedMessage>{message.content}</FormattedMessage>
                    </MessageAssistantText>
                ) : (
                    // TODO: refactor to remove React Native components
                    <GradientText text={texts.productDiscovery.thinkingPlaceholder} fontSize={16} />
                )}
            </AssistantMessageTextContainer>
            {isFeedbackMessage ? null : (
                <AssistantMessageIcons
                    {...{
                        message,
                        numberOfMessages,
                        selectedMessageIndexForProductOffers,
                        setSelectedMessageIndexForProductOffers,
                    }}
                />
            )}
        </AssistantMessageContainer>
    );
}

function AssistantMessageIcons({
    message,
    numberOfMessages,
    selectedMessageIndexForProductOffers,
    setSelectedMessageIndexForProductOffers,
}: {
    message: EnrichedProductDiscoveryConversationMessage;
    numberOfMessages: number;
    selectedMessageIndexForProductOffers?: number | undefined;
    setSelectedMessageIndexForProductOffers?: React.Dispatch<React.SetStateAction<number | undefined>>;
}) {
    return (
        <AssistantMessageIconsContainer>
            {Number(message.messageIndex) !== numberOfMessages - 1 ? (
                selectedMessageIndexForProductOffers === message.messageIndex ? (
                    <RewindArrowsImageContainer
                        onClick={() => setSelectedMessageIndexForProductOffers?.(numberOfMessages - 1)}>
                        <CrossCircleImage src={crossCircle} />
                    </RewindArrowsImageContainer>
                ) : (
                    <RewindArrowsImageContainer
                        onClick={() => setSelectedMessageIndexForProductOffers?.(message.messageIndex)}>
                        <RewindArrows />
                    </RewindArrowsImageContainer>
                )
            ) : null}
        </AssistantMessageIconsContainer>
    );
}

function MerchantProductOfferPlaceholderCards({ viewportWidth }: { viewportWidth: number }) {
    return (
        <>
            {Array.from({
                length: viewportWidth < 740 ? 2 : viewportWidth < 1024 ? 6 : 15,
            }).map((_, index) => (
                <MerchantProductOfferPlaceholderCard key={index} />
            ))}
        </>
    );
}

const MessagesSectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 24%;
    max-width: 24%;
    padding: 16px;
    background-color: ${colors.background.default};

    @media (max-width: 1500px) {
        min-width: 380px;
        max-width: 380px;
    }

    @media (max-width: 900px) {
        min-width: 320px;
        max-width: 320px;
    }

    @media (max-width: 650px) {
        min-width: 300px;
        max-width: 300px;
    }
`;

const MessagesHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    margin-bottom: 24px;
`;

export const MessagesTextContainer = styled.div<{ isFeedbackSection?: boolean }>`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 12px;
    overflow-x: hidden;
    overflow-y: auto;

    ${({ isFeedbackSection }) =>
        isFeedbackSection &&
        `
        position: absolute;
        left: 5%;
        max-width: calc(100% - 32px);
    `}

    ${NO_SCROLLBAR_CSS_MIXIN}
`;

export const MessagesTextInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
`;

const UserMessageContainer = styled.div<{ isFirstMessage: boolean }>`
    max-width: 70%;
    align-self: flex-end;
    padding: 12px 16px;
    margin-top: ${({ isFirstMessage }) => (isFirstMessage ? '0px' : '28px')};
    margin-bottom: 24px;
    border-radius: 12px;
    background-color: ${colors.background.light};
`;

const MessageUserText = styled.div`
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    line-height: 120%;
    white-space: pre-line;
`;

const AssistantMessageContainer = styled.div`
    flex-direction: column;
    align-self: flex-start;
`;

const AssistantMessageIconsContainer = styled.div`
    display: flex;
    height: 20px;
    align-items: center;
    margin-top: 8px;
    margin-left: 36px;
`;

const RewindArrowsImageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 8px;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}
`;

const AssistantMessageTextContainer = styled.div<{ isFeedbackMessage?: boolean }>`
    display: flex;
    flex-direction: row;
    width: ${({ isFeedbackMessage }) => (isFeedbackMessage ? 'auto' : 'calc(100% - 36px)')};
    align-items: flex-start;

    @media (min-width: 1500px) {
        width: ${({ isFeedbackMessage }) => (isFeedbackMessage ? 'calc(26vw - 44px)' : 'calc(100% - 44px)')};
    }

    @media (max-width: 1500px) {
        width: ${({ isFeedbackMessage }) => (isFeedbackMessage ? 'calc(412px - 44px)' : 'calc(100% - 44px)')};
    }

    @media (max-width: 900px) {
        width: ${({ isFeedbackMessage }) => (isFeedbackMessage ? 'calc(352px - 44px)' : 'calc(100% - 44px)')};
    }

    @media (max-width: 650px) {
        width: ${({ isFeedbackMessage }) => (isFeedbackMessage ? 'calc(332px - 44px)' : 'calc(100% - 44px)')};
    }
`;

const MessageAssistantText = styled.div`
    margin-top: 4px;
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    line-height: 120%;
`;

// ReactMarkdown wraps text content in <p> tags, which typically have a default margin applied by browsers.
// This is a workaround to remove the margin.
const FormattedMessage = styled(ReactMarkdown)`
    p {
        margin: 0;
    }
`;

const MessagesProductSuggestionsContainer = styled.div<{ isPlaceholder?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 24px;
    padding: 0 24px;
    overflow-y: ${({ isPlaceholder }) => (isPlaceholder ? 'hidden' : 'auto')};

    ${NO_SCROLLBAR_CSS_MIXIN}
`;

const SuggestedMerchantProductOffersContainer = styled.div<{ isPlaceholder?: boolean }>`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(5, minmax(0, 1fr)); // Ensures there are exactly 5 columns, with each taking equal space
    gap: 12px 24px;
    justify-content: space-between;
    ${({ isPlaceholder }) => (isPlaceholder ? '' : 'justify-items: center')}
    margin-bottom: 12px;

    @media (max-width: ${deviceWidthMeasurements.xLarge}) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media (max-width: ${deviceWidthMeasurements.large}) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: ${deviceWidthMeasurements.medium}) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`;

const JokoLogoMessageImage = styled.img`
    width: 32px;
    height: 32px;
    margin-right: 12px;
    flex-shrink: 0; // Prevent the logo from shrinking with the text
`;

export const LoadingInProgressContainer = styled.div`
    display: flex;
    padding: 24px;
    align-items: center;
    gap: 8px;
`;

export const LoadingInProgressText = styled.div`
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    line-height: 19.2px;
`;

export const NoMoreResultsText = styled.div`
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    line-height: 19.2px;
`;

const CrossCircleImage = styled.img`
    width: 18px;
    height: 18px;
    object-fit: contain;
`;
