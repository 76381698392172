import React from 'react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/react-hooks';

import { colors } from '../../../tokens/theme';
import { font } from '../../../style/text';
import { FeedbackInputBar } from './InputBar';
import { getRenderTextMessage, MessagesTextContainer, MessagesTextInnerContainer } from '../desktop/DesktopMessagesSection';
import { EnrichedProductDiscoveryConversationMessage } from '../../../lib/productDiscovery/conversationMessages';
import { CLICKABLE_ELEMENT_CSS_MIXIN } from '../../../style/styleConstants';
import { isMobileDevice } from '../../../style/utils';
import { getLocalizedTexts } from '../../../Locales';
import { logUserEventUtil } from '../../../lib/events/userEvents';

const closeIcon = '/assets/images/icons/cross-black-slim.svg';

export function FeedbackSection({
    shouldShowFeedbackSection,
    setShouldShowFeedbackSection,
    conversationId,
}: {
    shouldShowFeedbackSection: boolean;
    setShouldShowFeedbackSection: (value: boolean) => void;
    conversationId: string;
}) {
    const apolloClient = useApolloClient();
    const [inputText, setInputText] = React.useState('');
    const inputTextRef = React.useRef<HTMLTextAreaElement>(null);
    const renderTextMessage = getRenderTextMessage({});
    const texts = getLocalizedTexts().productDiscovery.feedback;
    const initialMessages = [
        {
            role: 'assistant',
            content: texts.defaultQuestion,
            messageIndex: 0,
        },
    ] as EnrichedProductDiscoveryConversationMessage[];
    const [messages, setMessages] = React.useState(initialMessages);
    React.useEffect(() => {
        if (shouldShowFeedbackSection) inputTextRef.current?.focus();
        else {
            setMessages(initialMessages);
            setInputText('');
        }
    }, [shouldShowFeedbackSection]);
    return (
        <FeedbackSectionContainer shouldShowFeedbackSection={shouldShowFeedbackSection}>
            {shouldShowFeedbackSection ? (
                <>
                    <FeedbackSectionHeader>
                        <FeedbackSectionTitle>
                            <FeedbackSectionTitleText>{texts.sectionTitle}</FeedbackSectionTitleText>
                        </FeedbackSectionTitle>
                        <CloseButton
                            onClick={() => {
                                setShouldShowFeedbackSection(false);
                                logUserEventUtil(apolloClient, {
                                    type: 'closedFeedbackSectionOnJokoAi',
                                    payload: { conversationId },
                                });
                            }}>
                            <CloseButtonIcon src={closeIcon} draggable={false} />
                        </CloseButton>
                    </FeedbackSectionHeader>
                    <FeedbackConversationContainer>
                        <MessagesTextContainer {...{ isFeedbackSection: true }}>
                            <MessagesTextInnerContainer>
                                {messages.map((message) =>
                                    renderTextMessage({
                                        item: message,
                                        numberOfMessages: messages.length,
                                        isFeedbackMessage: true,
                                    })
                                )}
                            </MessagesTextInnerContainer>
                        </MessagesTextContainer>
                    </FeedbackConversationContainer>
                    <FeedbackInputBar
                        {...{
                            inputText,
                            setInputText,
                            inputTextRef,
                            setMessages,
                            conversationId,
                            apolloClient,
                        }}
                    />
                </>
            ) : null}
        </FeedbackSectionContainer>
    );
}

const FeedbackSectionContainer = styled.div<{ shouldShowFeedbackSection: boolean }>`
    position: absolute;
    z-index: 3;
    top: ${isMobileDevice ? '0' : '49px'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: ${({ shouldShowFeedbackSection }) => (shouldShowFeedbackSection ? (isMobileDevice ? '100%' : '26%') : '0px')};
    height: ${isMobileDevice ? 'calc(100% - 32px)' : 'calc(100% - 49px - 32px)'};
    padding: 16px 0px;
    overflow: hidden;
    background: ${colors.background.default};
    transition: width 0.3s;

    @media (max-width: 100%) {
        background-color: ${colors.background.light};
    }

    @media (max-width: 1500px) {
        width: ${({ shouldShowFeedbackSection }) =>
            shouldShowFeedbackSection ? (isMobileDevice ? '100%' : '412px') : '0px'};
    }

    @media (max-width: 900px) {
        width: ${({ shouldShowFeedbackSection }) =>
            shouldShowFeedbackSection ? (isMobileDevice ? '100%' : '352px') : '0px'};
    }

    @media (max-width: 650px) {
        width: ${({ shouldShowFeedbackSection }) =>
            shouldShowFeedbackSection ? (isMobileDevice ? '100%' : '332px') : '0px'};
    }
`;

const FeedbackSectionHeader = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end; /* Align the close button to the right */
    align-items: center;
    width: 100%;
    border-bottom: ${isMobileDevice ? `1px solid ${colors.border.subtle}` : 'none'};
`;

const FeedbackSectionTitle = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 8px;
`;

const FeedbackSectionTitleText = styled.div`
    font-family: ${font.ambitSemiBold};
    font-size: 18px;
    text-align: center;
    color: ${colors.content.default};
    letter-spacing: -0.408px;
    white-space: nowrap;
`;

const CloseButton = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: ${isMobileDevice ? 'auto' : '8px'};
    margin-left: ${isMobileDevice ? '8px' : 'auto'};
    padding: 12px;
    border-radius: 96px;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}

    &:active {
        background-color: ${colors.background.subtle};
    }

    &:hover {
        background-color: ${colors.background.subtle};
        cursor: pointer;
    }
`;

const CloseButtonIcon = styled.img`
    width: 24px;
    height: 24px;
`;

const FeedbackConversationContainer = styled.div`
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    align-items: flex-end;
    width: calc(100% - 32px);
    gap: 24px;
    padding: 16px;
    overflow-x: hidden;
    overflow-y: auto;
`;
