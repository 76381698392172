import { getDevStackMode } from '../common/devStackMode';

enum WebSocketApiUrl {
    dev = 'wss://n5bnnvnz08.execute-api.eu-west-1.amazonaws.com/dev/',
    prod = 'wss://o23co1viz1.execute-api.eu-west-1.amazonaws.com/dev/',
    local = 'http://localhost:443',
}

const IS_JOKO_AI_LOCAL_WEBSOCKET_SERVER_ENABLED = false; // Set this to true for local tests

/**
 * This function returns the WebSocket API URL based on the environment.
 * If the IS_JOKO_AI_LOCAL_WEBSOCKET_SERVER_ENABLED constant is set to `true`, it will return the URL of the local WebSocket server implemented for local development of the Joko AI backend service.
 */
export function getWebSocketApiUrl(): string {
    if (IS_JOKO_AI_LOCAL_WEBSOCKET_SERVER_ENABLED) return WebSocketApiUrl.local;
    return getDevStackMode() ? WebSocketApiUrl.dev : WebSocketApiUrl.prod;
}
