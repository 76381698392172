import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';

import { getMerchantName } from '../merchants/merchants';
import { EnrichedProductDiscoveryConversationMessage } from './conversationMessages';

export type MerchantIdToMerchantNameMap = {
    [merchantId: string]: string | undefined;
};

/**
 * For all `merchantProductOffers` of new messages, this hook fetches the corresponding merchant names and stores them in a map
 */
export function useMerchantIdToMerchantNameMap({
    messages,
}: {
    messages: EnrichedProductDiscoveryConversationMessage[] | undefined;
}) {
    const apolloClient = useApolloClient();
    const [merchantIdToMerchantNameMap, setMerchantIdToMerchantNameMap] = React.useState<MerchantIdToMerchantNameMap>({});
    React.useEffect(() => {
        if (!messages?.length) return;
        const lastSuggestedMerchantProductOffers = messages[messages.length - 1].merchantProductOffers;
        if (!lastSuggestedMerchantProductOffers) return;
        const merchantIdsToFetch = lastSuggestedMerchantProductOffers
            .map((offer) => offer.merchantId)
            .filter(
                (merchantId) => !!merchantId && !merchantIdToMerchantNameMap[merchantId] // We only fetch a merchant name if it doesn't already exist in the map
            );
        const fetchMerchantNames = async () => {
            const merchantNames = await Promise.all(
                merchantIdsToFetch.map((merchantId) => getMerchantName(apolloClient, merchantId as string))
            );
            const newMerchantIdToMerchantNameMap = merchantNames.reduce(
                (newMap: { [merchantId: string]: string | undefined }, { merchantId, merchantName }) => {
                    newMap[merchantId] = merchantName;
                    return newMap;
                },
                {}
            );
            setMerchantIdToMerchantNameMap({ ...merchantIdToMerchantNameMap, ...newMerchantIdToMerchantNameMap });
        };
        fetchMerchantNames();
    }, [messages]);
    return merchantIdToMerchantNameMap;
}

export function useHandleScroll({
    listRef,
    listContent,
    shouldAutoScrollToBottom,
    scrollBehavior = 'smooth',
    onScrollCallback,
}: {
    listRef: React.MutableRefObject<HTMLDivElement | null>;
    listContent: unknown;
    /** If this is true, the list will automatically scroll to the bottom when new content is added to make it visible */
    shouldAutoScrollToBottom?: boolean;
    scrollBehavior?: ScrollBehavior;
    onScrollCallback?: (isAtBottom: boolean) => void;
}) {
    const [isAtBottom, setIsAtBottom] = React.useState(true);
    const previousScrollTopRef = React.useRef(0);
    const shouldDisableAutoScrollingRef = React.useRef(false);
    const onScroll = () => {
        const container = listRef.current;
        if (!container) return;
        const currentScrollTop = container.scrollTop;
        // If the user has scrolled up we disable the auto-scrolling when new content is added to let the user read the previous content
        if (currentScrollTop < previousScrollTopRef.current) shouldDisableAutoScrollingRef.current = true;
        previousScrollTopRef.current = currentScrollTop;
        const isAtBottom = Math.round(container.scrollTop + container.clientHeight) >= Math.round(container.scrollHeight);
        if (isAtBottom) {
            // We reactivate the auto-scrolling once the user scrolls back to the bottom
            shouldDisableAutoScrollingRef.current = false;
            setIsAtBottom(true);
        } else setIsAtBottom(false);
        onScrollCallback?.(isAtBottom);
    };
    React.useEffect(() => {
        const container = listRef.current;
        if (!container) return;
        if (shouldAutoScrollToBottom && !shouldDisableAutoScrollingRef.current) {
            setIsAtBottom(true);
            scrollToBottom(listRef, scrollBehavior);
            return;
        }
        if (!shouldAutoScrollToBottom) {
            const isAtBottom = container.scrollTop + container.clientHeight >= container.scrollHeight;
            setIsAtBottom(isAtBottom);
        }
    }, [listContent]);
    return { isAtBottom, setIsAtBottom, onScroll };
}

export function scrollToBottom(listRef: React.MutableRefObject<HTMLDivElement | null>, scrollBehavior: ScrollBehavior) {
    listRef.current?.scrollTo({ top: listRef.current.scrollHeight, behavior: scrollBehavior });
}
