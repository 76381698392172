import React from 'react';
import styled from 'styled-components';

import { CLICKABLE_ELEMENT_CSS_MIXIN, NO_SCROLLBAR_CSS_MIXIN } from '../../../style/styleConstants';
import { colors } from '../../../tokens/theme';
import { InfoTooltip } from '../common/InfoTooltip';
import { EnrichedProductDiscoveryConversationMessage } from '../../../lib/productDiscovery/conversationMessages';
import { useHandleScroll, useMerchantIdToMerchantNameMap } from '../../../lib/productDiscovery/displayLogic';
import { MerchantProductOfferCard } from '../common/MerchantProductOfferCard';
import { MoreResultsSpinningWheel } from '../../../components/common/SpinningWheel';
import {
    getScrollCallback,
    LoadingInProgressContainer,
    LoadingInProgressText,
    NoMoreResultsText,
} from '../desktop/DesktopMessagesSection';
import { getLocalizedTexts } from '../../../Locales';
import { Overlay } from '../common/Overlay';
import { JokoAiBetaTitle } from '../common/JokoAiBetaTitle';

const goBackIcon = '/assets/images/icons/chevron-left-straight.svg';

export function MobileAllProductSuggestions({
    messageIndexToDisplayAllProductSuggestions,
    setMessageIndexToDisplayAllProductSuggestions,
    shouldShowInfoTooltip,
    setShouldShowInfoTooltip,
    fetchMoreProductSuggestions,
    isFetchingMoreProductSuggestions,
    messages,
    conversationId,
}: {
    messageIndexToDisplayAllProductSuggestions: number;
    setMessageIndexToDisplayAllProductSuggestions: (value: number | undefined) => void;
    shouldShowInfoTooltip: boolean;
    setShouldShowInfoTooltip: (value: boolean) => void;
    fetchMoreProductSuggestions: (messageId: string, currentQueryScrollOffsets: number[] | undefined) => void;
    isFetchingMoreProductSuggestions: boolean;
    messages: EnrichedProductDiscoveryConversationMessage[];
    conversationId: string;
}) {
    const [isVisible, setIsVisible] = React.useState(false);
    React.useEffect(() => {
        setIsVisible(true);
    }, []);
    const message = messages.find((message) => message.messageIndex === messageIndexToDisplayAllProductSuggestions);
    const productSuggestionsListRef = React.useRef<HTMLDivElement | null>(null);
    const merchantIdToMerchantNameMap = useMerchantIdToMerchantNameMap({ messages });
    const { shouldDisplayNoMoreResultsText, onScrollCallback } = getScrollCallback({
        messages,
        conversationId,
        selectedMessageIndexForProductOffers: Number(messageIndexToDisplayAllProductSuggestions),
        fetchMoreProductSuggestions,
    });
    const { onScroll: onScrollProductSuggestionsList } = useHandleScroll({
        listRef: productSuggestionsListRef,
        listContent: messageIndexToDisplayAllProductSuggestions,
        onScrollCallback,
    });
    const handleClose = () => {
        setIsVisible(false);
        // We wait for the animation to finish before resetting the message position
        setTimeout(() => {
            setMessageIndexToDisplayAllProductSuggestions(undefined);
        }, 400);
    };
    const texts = getLocalizedTexts();
    return (
        <AllResultsContainer {...{ isVisible }}>
            <Overlay isVisible={shouldShowInfoTooltip} onClick={() => setShouldShowInfoTooltip(false)} />
            <MobileAllResultsHeader {...{ handleClose, shouldShowInfoTooltip, setShouldShowInfoTooltip }} />
            <MessageProductSuggestionsContainer ref={productSuggestionsListRef} onScroll={onScrollProductSuggestionsList}>
                <SuggestedMerchantProductOffersContainer>
                    {message?.merchantProductOffers?.map((offer) => (
                        <MerchantProductOfferCard
                            key={offer.merchantProductOfferId}
                            {...{
                                offer,
                                merchantIdToMerchantNameMap,
                                conversationId,
                                messageId: `${conversationId}|${messageIndexToDisplayAllProductSuggestions}`,
                            }}
                        />
                    ))}
                </SuggestedMerchantProductOffersContainer>
                {isFetchingMoreProductSuggestions ? (
                    <LoadingInProgressContainer>
                        <MoreResultsSpinningWheel size={16} />
                        <LoadingInProgressText>
                            {texts.productDiscovery.fetchingMoreProductSuggestions}
                        </LoadingInProgressText>
                    </LoadingInProgressContainer>
                ) : shouldDisplayNoMoreResultsText ? (
                    <LoadingInProgressContainer>
                        <NoMoreResultsText>{texts.productDiscovery.noMoreProductSuggestions}</NoMoreResultsText>
                    </LoadingInProgressContainer>
                ) : null}
            </MessageProductSuggestionsContainer>
        </AllResultsContainer>
    );
}

function MobileAllResultsHeader({
    handleClose,
    shouldShowInfoTooltip,
    setShouldShowInfoTooltip,
}: {
    handleClose: () => void;
    shouldShowInfoTooltip: boolean;
    setShouldShowInfoTooltip: (value: boolean) => void;
}) {
    return (
        <HeaderContainer>
            <HeaderImage src={goBackIcon} draggable={false} onClick={handleClose} />
            <JokoAiBetaTitle />
            <InfoTooltipContainer>
                <InfoTooltip {...{ shouldShowInfoTooltip, setShouldShowInfoTooltip }} />
            </InfoTooltipContainer>
        </HeaderContainer>
    );
}

const AllResultsContainer = styled.div<{ isVisible: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${colors.background.default};
    transform: ${({ isVisible }) => (isVisible ? 'translateX(0)' : 'translateX(100%)')};
    transition: transform 0.4s ease-in-out;
    z-index: 2; /* Ensures the page appears on top */
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 4px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${colors.border.subtle};
`;

const InfoTooltipContainer = styled.div`
    display: flex;
    padding: 12px;
`;

const HeaderImage = styled.img`
    width: 24px;
    height: 24px;
    padding: 12px;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}
`;

const MessageProductSuggestionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: auto;

    ${NO_SCROLLBAR_CSS_MIXIN}
`;

const SuggestedMerchantProductOffersContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)); // Ensures there are exactly 2 columns, with each taking equal space
    justify-items: center;
    gap: 16px 8px;
    margin-bottom: 12px;
    padding: 16px;
`;
