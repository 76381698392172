import React from 'react';
import * as _ from 'lodash';
import { useApolloClient } from '@apollo/react-hooks';

import { getApolloClient } from '../../api/config';
import { Feature, useHasFeature } from '../features/buildFeatureMap';
import { getWebSocketApiUrl } from './websockets';
import { getLocaleCode, getLocalizedTexts } from '../../Locales';
import {
    updateProductDiscoveryConversationMessageWithProductsListInCache,
    updateProductDiscoveryConversationsQueryInCache,
} from './conversationHistory';
import { UserProductDiscoveryConversationHistoryItem } from '../../api/graphql/fragments/productDiscovery';
import { logEventWithoutAuthentication } from '../../api/rest/events';
import { logUserEventUtil } from '../events/userEvents';
import { getDevStackMode } from '../common/devStackMode';
import { scrollToBottom } from './displayLogic';

type ProductDiscoveryConversationMessage = {
    messageIndex: number;
    content: string;
    role: 'system' | 'user' | 'assistant';
    timestamp: number;
};
export interface MerchantProductOffer {
    merchantId: string | null;
    merchantProductOfferId: string | null;
    originalTitle: string;
    processedWebpageUrl: string | null;
    mainImageUrls: MerchantProductOfferImageUrls;
    alternateImagesUrls?: MerchantProductOfferImageUrls[];
    priceInformation: {
        displayPriceAmount: {
            currency: string;
            valueInCents: number;
        };
    };
    contextualInformation: {
        originalMerchantName: string | null;
    };
}

export type MerchantProductOfferImageUrls = {
    defaultSize: string;
};

export type EnrichedProductDiscoveryConversationMessage = ProductDiscoveryConversationMessage & {
    merchantProductOffers?: MerchantProductOffer[];
    queryScrollOffsets?: number[];
};

type ConversationIdMessage = {
    messageType: 'conversationId';
    payload: {
        conversationId: string;
    };
};

type ConversationFromUserSearchQueryMessage = {
    messageType: 'conversationFromUserSearchQuery';
    payload: {
        conversationId: string;
        merchantProductOffers: MerchantProductOffer[];
    };
};

type StreamedAssistantMessage = {
    messageType: 'streamedAssistantMessage';
    payload: {
        conversationId: string;
        assistantMessageChunk: string;
    };
};

type FullAssistantMessage = {
    messageType: 'fullAssistantMessage';
    payload: {
        conversationId: string;
        content: string;
        merchantProductOffers: MerchantProductOffer[];
    };
};

type ConversationTitleMessage = {
    messageType: 'conversationTitle';
    payload: {
        conversationId: string;
        conversationTitle: string;
        createdAt: string;
    };
};

type GetProductDiscoveryConversationIdPayload = {
    action: 'getProductDiscoveryConversationId';
    userId: string | undefined;
};

type CloseProductDiscoveryConversationPayload = {
    action: 'closeProductDiscoveryConversation';
    userId: string | undefined;
    conversationId: string;
};

type StreamProductDiscoveryMessagePayload = {
    action: 'streamProductDiscoveryMessage';
    userId: string | undefined;
    timestamp: number;
    conversationId: string;
    message: string;
    localeCode: string;
    shouldLogAllData: boolean;
};

type CreateConversationFromUserSearchQueryPayload = {
    action: 'createConversationFromUserSearchQuery';
    userId: string;
    temporaryConversationId: string;
    userSearchQuery: string;
    assistantMessageContent: string;
    partialMerchantProductOffers: {
        merchantProductOfferId: string;
        priceInformation: {
            displayPriceAmount: {
                valueInCents: number;
                currency: string;
            };
        };
    }[];
};

type GetNextJokoAiProductSuggestionsPayload = {
    action: 'getNextJokoAiProductSuggestions';
    messageId: string;
    shouldLogAllData: boolean;
    queryScrollOffsets?: number[];
};

type WebSocketMessagePayload =
    | GetProductDiscoveryConversationIdPayload
    | CloseProductDiscoveryConversationPayload
    | StreamProductDiscoveryMessagePayload
    | CreateConversationFromUserSearchQueryPayload
    | GetNextJokoAiProductSuggestionsPayload;

function sendWebSocketMessage({
    webSocket,
    payload,
    isSandboxEnvironment,
}: {
    webSocket: WebSocket;
    payload: WebSocketMessagePayload;
    isSandboxEnvironment: boolean;
}) {
    if (webSocket.readyState === WebSocket.OPEN) {
        if (isSandboxEnvironment) console.log(`Sending WebSocket message: ${JSON.stringify(payload, null, 2)}`);
        webSocket.send(JSON.stringify(payload));
    } else if (isSandboxEnvironment)
        console.error(`WebSocket is not open, cannot send message: ${JSON.stringify(payload, null, 2)}`);
}

export function useHandleConnectionWithAssistant({
    userId,
    webSocketRef,
    messageIndexRef,
    setIsWebSocketConnectionOpen,
    messages,
    setMessages,
    setIsAssistantThinking,
    conversationId,
    setConversationId,
    temporaryConversationId,
    partialMerchantProductOffers,
    setLastConversationHistoryItem,
    setLastConversationTitle,
    setIsFetchingMoreProductSuggestions,
    sentMessageTimestampRef,
}: {
    userId: string | undefined;
    webSocketRef: React.MutableRefObject<WebSocket | undefined>;
    messageIndexRef: React.MutableRefObject<number>;
    setIsWebSocketConnectionOpen: (value: boolean) => void;
    messages: EnrichedProductDiscoveryConversationMessage[] | undefined;
    setMessages: React.Dispatch<React.SetStateAction<EnrichedProductDiscoveryConversationMessage[] | undefined>>;
    setIsAssistantThinking: (value: boolean) => void;
    conversationId: string;
    setConversationId: (value: string) => void;
    temporaryConversationId: string;
    partialMerchantProductOffers: MerchantProductOffer[];
    setLastConversationHistoryItem: (value: UserProductDiscoveryConversationHistoryItem | undefined) => void;
    setLastConversationTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
    setIsFetchingMoreProductSuggestions: (value: boolean) => void;
    sentMessageTimestampRef: React.MutableRefObject<{ messageIndex: number; timestamp: number } | undefined>;
}) {
    const isSandboxEnvironment = !!useHasFeature(Feature.useProductDiscoverySandboxEnvironment);
    const currentAssistantMessageRef = React.useRef<EnrichedProductDiscoveryConversationMessage | undefined>(undefined);
    useCloseWebSocketOnUnmount({ userId, conversationId, webSocketRef });
    /**
     * If the web socket is not connected when the user tries to send a message for some reason,
     * we initialize it again and rely on the `payloadToSendOnWebSocketOpen` to send the message right after the connection is open.
     */
    function initializeWebSocket({
        shouldGetNewConversationId,
        payloadToSendOnWebSocketOpen,
    }: {
        shouldGetNewConversationId?: boolean;
        payloadToSendOnWebSocketOpen?: WebSocketMessagePayload;
    }) {
        if (!userId) return;
        if (isSandboxEnvironment) console.log('Initializing WebSocket connection...');
        const webSocketUrl = getWebSocketApiUrl();
        const webSocket = new WebSocket(webSocketUrl + '?userId=' + userId);
        webSocketRef.current = webSocket;
        webSocket.onopen = () =>
            handleWebSocketOpen({
                webSocket,
                setIsWebSocketConnectionOpen,
                shouldGetNewConversationId,
                payloadToSendOnWebSocketOpen,
                userId,
                isSandboxEnvironment,
            });
        webSocket.onmessage = (event: MessageEvent) =>
            handleWebSocketMessage({
                event,
                isSandboxEnvironment,
                setConversationId,
                currentAssistantMessageRef,
                messageIndexRef,
                setMessages,
                setIsAssistantThinking,
                setLastConversationHistoryItem,
                setLastConversationTitle,
                setIsFetchingMoreProductSuggestions,
                sentMessageTimestampRef,
            });
        webSocket.onerror = (error) => {
            if (isSandboxEnvironment) console.error('WebSocket error:', error);
        };
        webSocket.onclose = (event: CloseEvent) => handleWebSocketClose({ event, isSandboxEnvironment });
    }
    function closeWebSocket({ conversationIdToClose }: { conversationIdToClose?: string }) {
        if (webSocketRef.current) {
            if (conversationIdToClose) {
                sendWebSocketMessage({
                    webSocket: webSocketRef.current,
                    payload: {
                        action: 'closeProductDiscoveryConversation',
                        userId,
                        conversationId: conversationIdToClose,
                    },
                    isSandboxEnvironment,
                });
                setConversationId('');
            }
            // We add a 1000 code to the close method to indicate a normal closure
            webSocketRef.current.close(1000);
        }
    }
    React.useEffect(() => {
        if (!userId) return;
        if (partialMerchantProductOffers.length) {
            // This happens when the user performs a search query and is redirected from the extension
            if (isSandboxEnvironment) console.log('Initializing WebSocket connection from URL params...');
            setIsAssistantThinking(true);
            if (!messages || messages.length < 2) {
                if (isSandboxEnvironment)
                    console.error(
                        'Messages are not defined or do not contain the user search query and the assistant message.'
                    );
                window.history.pushState({}, '', `/ai`);
                return;
            }
            initializeWebSocket({
                shouldGetNewConversationId: false,
                payloadToSendOnWebSocketOpen: createPayloadToSendWhenFromGoogleSearchQuery(
                    temporaryConversationId,
                    userId,
                    messages,
                    partialMerchantProductOffers
                ),
            });
        } else initializeWebSocket({ shouldGetNewConversationId: true });
    }, [userId]);
    return { initializeWebSocket, closeWebSocket };
}

function createPayloadToSendWhenFromGoogleSearchQuery(
    temporaryConversationId: string,
    userId: string,
    messages: EnrichedProductDiscoveryConversationMessage[],
    merchantProductOffers: MerchantProductOffer[]
): CreateConversationFromUserSearchQueryPayload {
    const partialMerchantProductOffers =
        merchantProductOffers.map((offer) => ({
            merchantProductOfferId: offer.merchantProductOfferId as string,
            priceInformation: offer.priceInformation,
        })) ?? [];
    const payload: WebSocketMessagePayload = {
        action: 'createConversationFromUserSearchQuery',
        userId,
        temporaryConversationId,
        userSearchQuery: messages[0].content,
        assistantMessageContent: messages[1].content,
        partialMerchantProductOffers,
    };
    return payload;
}

function useCloseWebSocketOnUnmount({
    userId,
    conversationId,
    webSocketRef,
}: {
    userId: string | undefined;
    conversationId: string;
    webSocketRef: React.MutableRefObject<WebSocket | undefined>;
}) {
    const isSandboxEnvironment = !!useHasFeature(Feature.useProductDiscoverySandboxEnvironment);
    React.useEffect(() => {
        if (!userId || !conversationId) return;
        const handleCloseConversationBeforeUnload = () => {
            if (webSocketRef.current?.readyState === WebSocket.OPEN) {
                sendWebSocketMessage({
                    webSocket: webSocketRef.current,
                    payload: {
                        action: 'closeProductDiscoveryConversation',
                        userId,
                        conversationId,
                    },
                    isSandboxEnvironment,
                });
                // We add a 1000 code to the close method to indicate a normal closure
                webSocketRef.current.close(1000);
            }
        };
        window.addEventListener('beforeunload', () => handleCloseConversationBeforeUnload());
    }, [userId, conversationId]);
}

function handleWebSocketOpen({
    webSocket,
    setIsWebSocketConnectionOpen,
    shouldGetNewConversationId,
    payloadToSendOnWebSocketOpen,
    userId,
    isSandboxEnvironment,
}: {
    webSocket: WebSocket;
    setIsWebSocketConnectionOpen: (value: boolean) => void;
    shouldGetNewConversationId?: boolean;
    payloadToSendOnWebSocketOpen?: WebSocketMessagePayload;
    userId: string;
    isSandboxEnvironment: boolean;
}) {
    setIsWebSocketConnectionOpen(true);
    if (payloadToSendOnWebSocketOpen) {
        // If we have a `payloadToSendOnWebSocketOpen` it means we want to continue the conversation with the same `conversationId`
        sendWebSocketMessage({ webSocket, payload: payloadToSendOnWebSocketOpen, isSandboxEnvironment });
        return;
    }
    if (shouldGetNewConversationId) {
        if (isSandboxEnvironment) console.log('Fetching a new conversation ID...');
        sendWebSocketMessage({
            webSocket,
            payload: {
                action: 'getProductDiscoveryConversationId',
                userId,
            },
            isSandboxEnvironment,
        });
    }
}

async function handleWebSocketMessage({
    event,
    isSandboxEnvironment,
    setConversationId,
    currentAssistantMessageRef,
    messageIndexRef,
    setMessages,
    setIsAssistantThinking,
    setLastConversationHistoryItem,
    setLastConversationTitle,
    setIsFetchingMoreProductSuggestions,
    sentMessageTimestampRef,
}: {
    event: MessageEvent;
    isSandboxEnvironment: boolean;
    setConversationId: (value: string) => void;
    currentAssistantMessageRef: React.MutableRefObject<EnrichedProductDiscoveryConversationMessage | undefined>;
    messageIndexRef: React.MutableRefObject<number>;
    setMessages: React.Dispatch<React.SetStateAction<EnrichedProductDiscoveryConversationMessage[] | undefined>>;
    setIsAssistantThinking: (value: boolean) => void;
    setLastConversationHistoryItem: (value: UserProductDiscoveryConversationHistoryItem | undefined) => void;
    setLastConversationTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
    setIsFetchingMoreProductSuggestions: (value: boolean) => void;
    sentMessageTimestampRef: React.MutableRefObject<{ messageIndex: number; timestamp: number } | undefined>;
}) {
    const webSocketMessage = JSON.parse(event.data);
    if (isSandboxEnvironment) console.log(`WebSocket message received: ${JSON.stringify(webSocketMessage, null, 2)}`);
    const messageType = webSocketMessage.messageType;
    switch (messageType) {
        case 'conversationId':
            handleReceivedConversationId({ webSocketMessage, setConversationId, isSandboxEnvironment });
            break;
        case 'conversationFromUserSearchQuery':
            handleReceivedConversationFromUserSearchQuery({
                webSocketMessage,
                setMessages,
                messageIndexRef,
                setConversationId,
                setIsAssistantThinking,
                isSandboxEnvironment,
            });
            break;
        case 'streamedAssistantMessage':
            handleReceivedStreamedAssistantMessage({
                webSocketMessage,
                currentAssistantMessageRef,
                messageIndexRef,
                setMessages,
                setLastConversationHistoryItem,
                isSandboxEnvironment,
                sentMessageTimestampRef,
            });
            break;
        case 'fullAssistantMessage':
            handleReceivedFullAssistantMessage({
                webSocketMessage,
                currentAssistantMessageRef,
                setMessages,
                setIsAssistantThinking,
                isSandboxEnvironment,
                sentMessageTimestampRef,
            });
            break;
        case 'conversationTitle':
            handleReceivedConversationTitle({
                webSocketMessage,
                setLastConversationHistoryItem,
                setLastConversationTitle,
                isSandboxEnvironment,
            });
            break;
        case 'nextJokoAiProductSuggestions':
            handleReceivedNextProductSuggestions({
                webSocketMessage,
                currentAssistantMessageRef,
                setMessages,
                setIsFetchingMoreProductSuggestions,
            });
            break;
        default:
            if (isSandboxEnvironment) console.warn('Unknown message type:', messageType);
    }
}

function handleReceivedConversationId({
    webSocketMessage,
    setConversationId,
    isSandboxEnvironment,
}: {
    webSocketMessage: ConversationIdMessage;
    setConversationId: (value: string) => void;
    isSandboxEnvironment: boolean;
}) {
    const { payload } = webSocketMessage;
    if (!payload?.conversationId) {
        if (isSandboxEnvironment) console.warn(`Unexpected payload for message ${webSocketMessage.messageType}:`, payload);
        return;
    }
    setConversationId(payload.conversationId);
    if (isSandboxEnvironment) console.log('Received product discovery conversation ID:', payload.conversationId);
}

function handleReceivedConversationFromUserSearchQuery({
    webSocketMessage,
    setMessages,
    messageIndexRef,
    setConversationId,
    setIsAssistantThinking,
    isSandboxEnvironment,
}: {
    webSocketMessage: ConversationFromUserSearchQueryMessage;
    setMessages: React.Dispatch<React.SetStateAction<EnrichedProductDiscoveryConversationMessage[] | undefined>>;
    messageIndexRef: React.MutableRefObject<number>;
    setConversationId: (value: string) => void;
    setIsAssistantThinking: (value: boolean) => void;
    isSandboxEnvironment: boolean;
}) {
    const { payload } = webSocketMessage;
    if (!payload?.conversationId || !payload?.merchantProductOffers) {
        if (isSandboxEnvironment) console.warn(`Unexpected payload for message ${webSocketMessage.messageType}:`, payload);
        return;
    }
    setMessages((prevMessages) =>
        prevMessages?.map((message) =>
            message.messageIndex === 1
                ? {
                      ...message,
                      merchantProductOffers: payload.merchantProductOffers,
                  }
                : message
        )
    );
    messageIndexRef.current = 1;
    setConversationId(payload.conversationId);
    setIsAssistantThinking(false);
    // We use a `replaceState` so that if the user makes a "go back" we won't redirect to the custom URL containing all the initial information from the search query
    window.history.replaceState({}, '', `/ai/${payload.conversationId}`);
}

/**
 * The API sends back either chunks of a message when sending back the streamed assistant response,
 * or the full message when the assistant has finished processing the user's message. The full message also
 * contains the merchant product offers, that we use to display the products within the conversation.
 */
async function handleReceivedStreamedAssistantMessage({
    webSocketMessage,
    currentAssistantMessageRef,
    messageIndexRef,
    setMessages,
    setLastConversationHistoryItem,
    isSandboxEnvironment,
    sentMessageTimestampRef,
}: {
    webSocketMessage: StreamedAssistantMessage;
    currentAssistantMessageRef: React.MutableRefObject<EnrichedProductDiscoveryConversationMessage | undefined>;
    messageIndexRef: React.MutableRefObject<number>;
    setMessages: React.Dispatch<React.SetStateAction<EnrichedProductDiscoveryConversationMessage[] | undefined>>;
    setLastConversationHistoryItem: (value: UserProductDiscoveryConversationHistoryItem | undefined) => void;
    isSandboxEnvironment: boolean;
    sentMessageTimestampRef: React.MutableRefObject<{ messageIndex: number; timestamp: number } | undefined>;
}) {
    const { payload } = webSocketMessage;
    if (!payload?.conversationId || !payload?.assistantMessageChunk) {
        if (isSandboxEnvironment) console.warn(`Unexpected payload for message ${webSocketMessage.messageType}:`, payload);
        return;
    }
    if (currentAssistantMessageRef.current === undefined) {
        if (sentMessageTimestampRef.current)
            logEventWithoutAuthentication(
                undefined,
                {
                    type: 'frontendProductDiscoveryLatencyDataGenerated',
                    payload: {
                        type: 'firstAssistantMessageChunkReceived',
                        latency: Date.now() - sentMessageTimestampRef.current.timestamp,
                        messageId: `${payload.conversationId}|${sentMessageTimestampRef.current.messageIndex}`,
                    },
                },
                getDevStackMode()
            );
        const assistantMessage: EnrichedProductDiscoveryConversationMessage = {
            messageIndex: messageIndexRef.current,
            timestamp: Date.now(),
            content: payload.assistantMessageChunk,
            role: 'assistant',
        };
        if (messageIndexRef.current === 1 && payload.conversationId) {
            const newConversationTitlePlaceholder = getLocalizedTexts().productDiscovery.newConversationTitlePlaceholder;
            // We use the current date as a placeholder until we receive the real date with the "conversationTitle" message
            const createdAtPlaceholder = new Date().toISOString();
            setLastConversationHistoryItem({
                __typename: 'UserProductDiscoveryConversationHistoryItem',
                conversationId: payload.conversationId,
                createdAt: createdAtPlaceholder,
                conversationTitle: newConversationTitlePlaceholder,
            });
            const apolloClient = await getApolloClient();
            if (apolloClient && payload.conversationId) {
                updateProductDiscoveryConversationsQueryInCache({
                    apolloClient,
                    conversationId: payload.conversationId,
                    createdAt: createdAtPlaceholder,
                    isSandboxEnvironment,
                });
                // We wait for the first chunk of the assistant message to update the URL so that we know the backend has processed the user's message
                window.history.pushState({}, '', `/ai/${payload.conversationId}`);
            }
        }
        currentAssistantMessageRef.current = assistantMessage;
        setMessages((prevMessages) =>
            prevMessages?.map((message) =>
                message.messageIndex === currentAssistantMessageRef.current?.messageIndex ? assistantMessage : message
            )
        );
    } else {
        const updatedMessage = {
            ...currentAssistantMessageRef.current,
            content: (currentAssistantMessageRef.current.content || '') + payload.assistantMessageChunk,
        };
        currentAssistantMessageRef.current = updatedMessage;
        setMessages((prevMessages) =>
            prevMessages?.map((message) =>
                message.messageIndex === currentAssistantMessageRef.current?.messageIndex ? updatedMessage : message
            )
        );
    }
}

async function handleReceivedFullAssistantMessage({
    webSocketMessage,
    currentAssistantMessageRef,
    setMessages,
    setIsAssistantThinking,
    isSandboxEnvironment,
    sentMessageTimestampRef,
}: {
    webSocketMessage: FullAssistantMessage;
    currentAssistantMessageRef: React.MutableRefObject<EnrichedProductDiscoveryConversationMessage | undefined>;
    setMessages: React.Dispatch<React.SetStateAction<EnrichedProductDiscoveryConversationMessage[] | undefined>>;
    setIsAssistantThinking: (value: boolean) => void;
    isSandboxEnvironment: boolean;
    sentMessageTimestampRef: React.MutableRefObject<{ messageIndex: number; timestamp: number } | undefined>;
}) {
    const { payload } = webSocketMessage;
    if (!payload?.conversationId || !payload?.content || !payload?.merchantProductOffers) {
        if (isSandboxEnvironment) console.warn(`Unexpected payload for message ${webSocketMessage.messageType}:`, payload);
        return;
    }
    if (!currentAssistantMessageRef.current) {
        if (isSandboxEnvironment) console.warn('Received assistant content but no assistant message was found.');
        return;
    }
    const updatedMessage = {
        ...currentAssistantMessageRef.current,
        content: payload.content || currentAssistantMessageRef.current.content || '',
        merchantProductOffers: payload.merchantProductOffers || [],
    };
    currentAssistantMessageRef.current = undefined;
    let newMessages: EnrichedProductDiscoveryConversationMessage[] | undefined;
    setMessages((prevMessages) => {
        newMessages = prevMessages?.map((message) =>
            message.messageIndex === updatedMessage.messageIndex ? updatedMessage : message
        );
        return newMessages;
    });
    if (sentMessageTimestampRef.current)
        logEventWithoutAuthentication(
            undefined,
            {
                type: 'frontendProductDiscoveryLatencyDataGenerated',
                payload: {
                    type: 'firstProductSuggestionBatchReceived',
                    latency: Date.now() - sentMessageTimestampRef.current.timestamp,
                    messageId: `${payload.conversationId}|${sentMessageTimestampRef.current.messageIndex}`,
                },
            },
            getDevStackMode()
        );
    setIsAssistantThinking(false);
    const conversationId = payload.conversationId;
    if (conversationId && newMessages) {
        const apolloClient = await getApolloClient();
        if (!apolloClient) return;
        updateProductDiscoveryConversationMessageWithProductsListInCache(
            apolloClient,
            conversationId,
            isSandboxEnvironment,
            newMessages.map((message) => ({
                ...message,
                __typename: 'UserProductDiscoveryMessageWithProducts',
                messageId: `${conversationId}|${message.messageIndex}`,
                merchantProductOffers: message.merchantProductOffers
                    ? message.merchantProductOffers.map((offer) => {
                          return {
                              __typename: 'MerchantProductOffer',
                              ...offer,
                          };
                      })
                    : [],
            }))
        );
    }
}

async function handleReceivedConversationTitle({
    webSocketMessage,
    setLastConversationHistoryItem,
    setLastConversationTitle,
    isSandboxEnvironment,
}: {
    webSocketMessage: ConversationTitleMessage;
    setLastConversationHistoryItem: (value: UserProductDiscoveryConversationHistoryItem | undefined) => void;
    setLastConversationTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
    isSandboxEnvironment: boolean;
}) {
    const { payload } = webSocketMessage;
    if (!payload?.conversationId || !payload?.conversationTitle || !payload?.createdAt) {
        if (isSandboxEnvironment) console.warn(`Unexpected payload for message ${webSocketMessage.messageType}:`, payload);
        return;
    }
    setLastConversationHistoryItem({
        __typename: 'UserProductDiscoveryConversationHistoryItem',
        conversationId: payload.conversationId,
        createdAt: payload.createdAt,
        conversationTitle: payload.conversationTitle,
    });
    const apolloClient = await getApolloClient();
    if (apolloClient)
        updateProductDiscoveryConversationsQueryInCache({
            apolloClient,
            conversationId: payload.conversationId,
            createdAt: payload.createdAt,
            generatedConversationTitle: payload.conversationTitle,
            isSandboxEnvironment,
        });
    // We animate the conversation title by displaying it character by character
    let index = 0;
    const characters = payload.conversationTitle.split('') as string[];
    const intervalId = setInterval(() => {
        setLastConversationTitle((prevConversationTitle) => (prevConversationTitle || '') + characters[index]);
        index++;
        if (index === characters.length) clearInterval(intervalId);
    }, 30);
}

function handleWebSocketClose({ event, isSandboxEnvironment }: { event: CloseEvent; isSandboxEnvironment: boolean }) {
    if (isSandboxEnvironment) {
        if (event.code === 1000) console.log(`WebSocket closed with code: ${event.code}`);
        else console.error(`WebSocket closed with an the unexpected code: ${event.code}`);
    }
}

function handleReceivedNextProductSuggestions({
    webSocketMessage,
    setMessages,
    setIsFetchingMoreProductSuggestions,
}: {
    webSocketMessage: any;
    currentAssistantMessageRef: React.MutableRefObject<EnrichedProductDiscoveryConversationMessage | undefined>;
    setMessages: React.Dispatch<React.SetStateAction<EnrichedProductDiscoveryConversationMessage[] | undefined>>;
    setIsFetchingMoreProductSuggestions: (value: boolean) => void;
}) {
    const messageId = webSocketMessage.payload.messageId;
    const conversationId = messageId.split('|')[0];
    setMessages((prev) => {
        return prev?.map((message) => {
            const currentMessageId = `${conversationId}|${message.messageIndex}`;
            if (currentMessageId === messageId) {
                return {
                    ...message,
                    merchantProductOffers: _.uniqBy(
                        [...(message.merchantProductOffers || []), ...webSocketMessage.payload.merchantProductOffers],
                        'merchantProductOfferId'
                    ),
                    queryScrollOffsets: webSocketMessage.payload.queryScrollOffsets,
                };
            }
            return message;
        });
    });
    setIsFetchingMoreProductSuggestions(false);
}

export function useSendMessage({
    userId,
    webSocketRef,
    isWebSocketConnectionOpen,
    messageIndexRef,
    messages,
    setMessages,
    conversationId,
    inputText,
    setInputText,
    initializeWebSocket,
    messagesListRef,
    setIsAssistantThinking,
    firstMessageToSend,
    setFirstMessageToSend,
    sentMessageTimestampRef,
}: {
    userId: string | undefined;
    webSocketRef: React.MutableRefObject<WebSocket | undefined>;
    isWebSocketConnectionOpen: boolean;
    messageIndexRef: React.MutableRefObject<number>;
    messages: EnrichedProductDiscoveryConversationMessage[] | undefined;
    setMessages: React.Dispatch<React.SetStateAction<EnrichedProductDiscoveryConversationMessage[] | undefined>>;
    conversationId: string;
    inputText: string;
    setInputText: React.Dispatch<React.SetStateAction<string>>;
    initializeWebSocket: ({
        shouldGetNewConversationId,
        payloadToSendOnWebSocketOpen,
    }: {
        shouldGetNewConversationId?: boolean;
        payloadToSendOnWebSocketOpen?: WebSocketMessagePayload;
    }) => void;
    messagesListRef: React.MutableRefObject<HTMLDivElement | null>;
    setIsAssistantThinking: (value: boolean) => void;
    firstMessageToSend: string | undefined;
    setFirstMessageToSend: (value: string) => void;
    sentMessageTimestampRef: React.MutableRefObject<{ messageIndex: number; timestamp: number } | undefined>;
}) {
    const apolloClient = useApolloClient();
    const isSandboxEnvironment = !!useHasFeature(Feature.useProductDiscoverySandboxEnvironment);
    const localeCode = React.useMemo(() => getLocaleCode(), []);
    useSendMessageOnConversationInit({
        userId,
        webSocketRef,
        messageIndexRef,
        isWebSocketConnectionOpen,
        messages,
        setMessages,
        conversationId,
        firstMessageToSend,
        localeCode,
        isSandboxEnvironment,
        setInputText,
        setIsAssistantThinking,
    });
    const sendMessage = (suggestedMessage?: string | undefined) => {
        sentMessageTimestampRef.current = {
            messageIndex: (messages ?? []).length,
            timestamp: Date.now(),
        };
        const messageToSend = suggestedMessage ? suggestedMessage : inputText;
        if (messageToSend.trim().length === 0) return;
        if (!conversationId || !webSocketRef.current || !isWebSocketConnectionOpen) {
            // If the `conversationId` is not created yet, we store the message and send it as soon as the connection is open
            setFirstMessageToSend(messageToSend);
            return;
        }
        const userMessageIndex = messageIndexRef.current === 0 ? 0 : incrementAndReturnMessageIndex(messageIndexRef);
        const payload: WebSocketMessagePayload = {
            action: 'streamProductDiscoveryMessage',
            userId,
            timestamp: Date.now(),
            conversationId,
            message: messageToSend,
            localeCode,
            shouldLogAllData: true,
        };
        const userMessage: EnrichedProductDiscoveryConversationMessage = {
            messageIndex: userMessageIndex,
            timestamp: Date.now(),
            content: messageToSend,
            role: 'user',
        };
        const assistantMessageIndex = incrementAndReturnMessageIndex(messageIndexRef);
        setMessages((prevMessages) => [
            ...(prevMessages || []),
            userMessage,
            {
                messageIndex: assistantMessageIndex,
                content: '',
                role: 'assistant',
                timestamp: Date.now(),
            },
        ]);
        // If the web socket is not connected when the user tries to send a message, we force a reconnection and send the message right after
        if (webSocketRef.current?.readyState !== WebSocket.OPEN) {
            if (isSandboxEnvironment) console.log('WebSocket connection is not open. Reconnecting...');
            initializeWebSocket({ shouldGetNewConversationId: false, payloadToSendOnWebSocketOpen: payload });
        } else sendWebSocketMessage({ webSocket: webSocketRef.current, payload, isSandboxEnvironment });
        setInputText('');
        setIsAssistantThinking(true);
        setTimeout(
            () => messagesListRef.current?.scrollTo({ top: messagesListRef.current.scrollHeight, behavior: 'smooth' }),
            100
        ); // We scroll to the end of the list after a delay to ensure the message is rendered
        logUserEventUtil(apolloClient, {
            type: 'sentMessageOnJokoAi',
            payload: {
                conversationId,
                messageId: `${conversationId}|${userMessageIndex}`,
            },
        });
    };
    return sendMessage;
}

export function useFetchMoreProductSuggestions({
    webSocketRef,
    isWebSocketConnectionOpen,
}: {
    webSocketRef: React.MutableRefObject<WebSocket | undefined>;
    isWebSocketConnectionOpen: boolean;
}) {
    const [isFetchingMoreProductSuggestions, setIsFetchingMoreProductSuggestions] = React.useState(false);
    const isSandboxEnvironment = !!useHasFeature(Feature.useProductDiscoverySandboxEnvironment);
    const fetchMoreProductSuggestions = async (messageId: string, currentQueryScrollOffsets: number[] | undefined) => {
        if (isFetchingMoreProductSuggestions) return;
        if (!webSocketRef.current || !isWebSocketConnectionOpen) {
            setIsFetchingMoreProductSuggestions(false);
            return;
        }
        setIsFetchingMoreProductSuggestions(true);
        const payload: WebSocketMessagePayload = {
            action: 'getNextJokoAiProductSuggestions',
            messageId,
            shouldLogAllData: true,
            queryScrollOffsets: currentQueryScrollOffsets,
        };
        sendWebSocketMessage({ webSocket: webSocketRef.current, payload, isSandboxEnvironment });
    };
    return { fetchMoreProductSuggestions, isFetchingMoreProductSuggestions, setIsFetchingMoreProductSuggestions };
}

/**
 * If the first message has been entered by the user before the connection was initialized, we send it as soon the connection is open.
 * To do so, this hook relies on the `firstMessageToSend` and the `conversationId` states:
 * - If `conversationId` is not yet initialized when the first message was entered, the message is saved in `firstMessageToSend`,
 *   allowing this effect to fake the sending of the message, waiting for the conversation ID to be created.
 * - When `conversationId` is initialized, this effect re-renders and sends the first message immediately.
 */
function useSendMessageOnConversationInit({
    userId,
    webSocketRef,
    isWebSocketConnectionOpen,
    messageIndexRef,
    messages,
    setMessages,
    conversationId,
    firstMessageToSend,
    localeCode,
    isSandboxEnvironment,
    setInputText,
    setIsAssistantThinking,
}: {
    userId: string | undefined;
    webSocketRef: React.MutableRefObject<WebSocket | undefined>;
    isWebSocketConnectionOpen: boolean;
    messageIndexRef: React.MutableRefObject<number>;
    messages: EnrichedProductDiscoveryConversationMessage[] | undefined;
    setMessages: React.Dispatch<React.SetStateAction<EnrichedProductDiscoveryConversationMessage[] | undefined>>;
    conversationId: string;
    firstMessageToSend: string | undefined;
    localeCode: string;
    isSandboxEnvironment: boolean;
    setInputText: (value: string) => void;
    setIsAssistantThinking: (value: boolean) => void;
}) {
    const apolloClient = useApolloClient();
    React.useEffect(() => {
        if (!firstMessageToSend) return;
        if (conversationId && webSocketRef.current && isWebSocketConnectionOpen) {
            // As soon as the `conversationId` is initialized, we send the first message
            const payload: WebSocketMessagePayload = {
                action: 'streamProductDiscoveryMessage',
                userId,
                timestamp: Date.now(),
                conversationId,
                message: firstMessageToSend,
                localeCode,
                shouldLogAllData: true,
            };
            if (isSandboxEnvironment) console.log('Sending the first message after connection was initialized');
            sendWebSocketMessage({ webSocket: webSocketRef.current, payload, isSandboxEnvironment });
            incrementAndReturnMessageIndex(messageIndexRef);
            logUserEventUtil(apolloClient, {
                type: 'sentMessageOnJokoAi',
                payload: {
                    conversationId,
                    messageId: `${conversationId}|0`,
                },
            });
            return;
        }
        if (isSandboxEnvironment) console.log('The first message has been entered before the connection was initialized');
        const userMessage: EnrichedProductDiscoveryConversationMessage = {
            messageIndex: messageIndexRef.current,
            timestamp: Date.now(),
            content: firstMessageToSend,
            role: 'user',
        };
        const newAssistantMessageIndex = messageIndexRef.current + 1;
        if (messages && messages[messages.length - 1]?.messageIndex === newAssistantMessageIndex) return;
        setMessages((prevMessages) => [
            ...(prevMessages || []),
            userMessage,
            {
                messageIndex: newAssistantMessageIndex,
                content: '',
                role: 'assistant',
                timestamp: Date.now(),
            },
        ]);
        setInputText('');
        setIsAssistantThinking(true);
    }, [conversationId, firstMessageToSend, isWebSocketConnectionOpen]);
}

function incrementAndReturnMessageIndex(messageIndexRef: React.MutableRefObject<number>) {
    messageIndexRef.current += 1;
    return messageIndexRef.current;
}

export function useResetConversation({
    conversationId,
    setMessages,
    messageIndexRef,
    messagesListRef,
    setIsAssistantThinking,
    setFirstMessageToSend,
    setConversationId,
    initializeWebSocket,
    closeWebSocket,
    setIsAtBottom,
    setSelectedConversationHistoryItemId,
    setFailedToFetchConversation,
    setInputText,
    setLastConversationHistoryItem,
    setLastConversationTitle,
    setIsFetchingMoreProductSuggestions,
}: {
    conversationId: string;
    setMessages: React.Dispatch<React.SetStateAction<EnrichedProductDiscoveryConversationMessage[] | undefined>>;
    messageIndexRef: React.MutableRefObject<number>;
    messagesListRef: React.MutableRefObject<HTMLDivElement | null>;
    setIsAssistantThinking: (value: boolean) => void;
    setFirstMessageToSend: (value: string | undefined) => void;
    setConversationId: (value: string) => void;
    initializeWebSocket: ({
        shouldGetNewConversationId,
        payloadToSendOnWebSocketOpen,
    }: {
        shouldGetNewConversationId?: boolean;
        payloadToSendOnWebSocketOpen?: WebSocketMessagePayload;
    }) => void;
    closeWebSocket: ({ conversationIdToClose }: { conversationIdToClose?: string }) => void;
    setIsAtBottom?: (value: boolean) => void; // We keep this optional to keep the compatibility with the previous design of the product discovery page
    setSelectedConversationHistoryItemId: (value: string | undefined) => void;
    setFailedToFetchConversation: (value: boolean) => void;
    setInputText: (value: string) => void;
    setLastConversationHistoryItem: (value: UserProductDiscoveryConversationHistoryItem | undefined) => void;
    setLastConversationTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
    setIsFetchingMoreProductSuggestions: (value: boolean) => void;
}) {
    const isSandboxEnvironment = !!useHasFeature(Feature.useProductDiscoverySandboxEnvironment);
    return React.useCallback(
        ({
            conversationToReopen,
        }: {
            conversationToReopen?: {
                conversationId: string;
                messages: EnrichedProductDiscoveryConversationMessage[] | undefined;
            };
        }) => {
            if (isSandboxEnvironment)
                console.log(
                    `Resetting conversation:\n conversationIdToClose: ${conversationId} \n conversationIdToReopen: ${conversationToReopen?.conversationId}`
                );
            // We close the web socket connection so that we can open a new one with a new conversation ID
            closeWebSocket({ conversationIdToClose: conversationId });
            if (conversationToReopen && conversationToReopen.messages) {
                window.history.pushState({}, '', `/ai/${conversationToReopen.conversationId}`);
                setConversationId(conversationToReopen.conversationId);
                setMessages(conversationToReopen.messages);
                setTimeout(() => scrollToBottom(messagesListRef, 'instant'), 0); // We scroll to the bottom of the list after a delay to ensure the messages are rendered
                messageIndexRef.current = conversationToReopen.messages.length - 1;
                initializeWebSocket({ shouldGetNewConversationId: false });
            } else {
                window.history.pushState({}, '', `/ai/`);
                setConversationId('');
                setMessages(undefined);
                setSelectedConversationHistoryItemId(undefined);
                messageIndexRef.current = 0;
                initializeWebSocket({ shouldGetNewConversationId: true });
            }
            setFailedToFetchConversation(false);
            setIsAssistantThinking(false);
            setFirstMessageToSend(undefined);
            setIsAtBottom?.(false);
            setInputText('');
            setLastConversationHistoryItem(undefined);
            setLastConversationTitle(undefined);
            setIsFetchingMoreProductSuggestions(false);
        },
        [conversationId]
    );
}

export function useSuggestedProductsDebugLogs({
    messages,
}: {
    messages: EnrichedProductDiscoveryConversationMessage[] | undefined;
}) {
    const isSandboxEnvironment = !!useHasFeature(Feature.useProductDiscoverySandboxEnvironment);
    React.useEffect(() => {
        if (!isSandboxEnvironment || !messages?.length) return;
        const newSuggestedMerchantProductOffers = messages[messages.length - 1].merchantProductOffers;
        if (!newSuggestedMerchantProductOffers) return;
        console.log('Suggested MPOs:', JSON.stringify(newSuggestedMerchantProductOffers, null, 2));
    }, [messages, isSandboxEnvironment]);
}
