import React from 'react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/react-hooks';

import { UserProductDiscoveryConversationHistoryItem } from '../../../api/graphql/fragments/productDiscovery';
import { useShouldShowConversationHistory } from '../../../lib/productDiscovery/conversationHistory';
import { EnrichedProductDiscoveryConversationMessage } from '../../../lib/productDiscovery/conversationMessages';
import { Overlay } from '../common/Overlay';
import { ConversationHistorySection } from '../common/ConversationHistorySection';
import { LandingPageContent } from '../common/LandingPageContent';
import { InputBar } from '../common/InputBar';
import { ErrorMessageOnFetchConversation } from '../common/ErrorMessage';
import { deviceWidthMeasurements } from '../../../style/size';
import { useViewportSize } from '../../../style/utils';
import { colors } from '../../../tokens/theme';
import DesktopMessagesSection from './DesktopMessagesSection';
import { DesktopHeader } from './DesktopHeader';
import { FeedbackSection } from '../common/FeedbackSection';
import { logUserEventUtil } from '../../../lib/events/userEvents';

export function DesktopProductDiscoveryPage({
    conversationId,
    resetConversation,
    messages,
    setMessages,
    messagesListRef,
    inputText,
    setInputText,
    selectedConversationHistoryItemId,
    setSelectedConversationHistoryItemId,
    failedToFetchConversation,
    setFailedToFetchConversation,
    sendMessage,
    isAssistantThinking,
    setIsAssistantThinking,
    lastConversationHistoryItem,
    lastConversationTitle,
    fetchMoreProductSuggestions,
    isFetchingMoreProductSuggestions,
    isFromSerpEmbedding,
}: {
    conversationId: string;
    resetConversation: ({
        conversationToReopen,
    }: {
        conversationToReopen?: {
            conversationId: string;
            messages: EnrichedProductDiscoveryConversationMessage[];
        };
    }) => void;
    messages: EnrichedProductDiscoveryConversationMessage[] | undefined;
    setMessages: (messages: EnrichedProductDiscoveryConversationMessage[] | undefined) => void;
    messagesListRef: React.RefObject<HTMLDivElement | null>;
    inputText: string;
    setInputText: (value: string) => void;
    selectedConversationHistoryItemId: string | undefined;
    setSelectedConversationHistoryItemId: (value: string | undefined) => void;
    failedToFetchConversation: boolean;
    setFailedToFetchConversation: (value: boolean) => void;
    sendMessage: (suggestedMessage?: string) => void;
    isAssistantThinking: boolean;
    setIsAssistantThinking: (value: boolean) => void;
    lastConversationHistoryItem: UserProductDiscoveryConversationHistoryItem | undefined;
    lastConversationTitle: string | undefined;
    fetchMoreProductSuggestions: (messageId: string, currentQueryScrollOffsets: number[] | undefined) => void;
    isFetchingMoreProductSuggestions: boolean;
    isFromSerpEmbedding: boolean;
}) {
    const apolloClient = useApolloClient();
    const { height: viewportHeight } = useViewportSize();
    const inputTextRef = React.useRef<HTMLTextAreaElement>(null);
    const { shouldShowConversationHistory, setShouldShowConversationHistory } = useShouldShowConversationHistory();
    const [isInputBarFocused, setIsInputBarFocused] = React.useState(false);
    const [shouldShowInfoTooltip, setShouldShowInfoTooltip] = React.useState(false);
    usePreventWebpageScroll(shouldShowConversationHistory);
    const [bottomPositionScrollButton, setBottomPositionScrollButton] = React.useState(0);
    const [shouldShowFeedbackSection, setShouldShowFeedbackSection] = React.useState(false);
    return (
        <>
            <Overlay
                {...{
                    isVisible: shouldShowConversationHistory || shouldShowFeedbackSection,
                    isHeaderVisible: shouldShowFeedbackSection,
                }}
                onClick={() => {
                    if (shouldShowConversationHistory) setShouldShowConversationHistory(false);
                    if (shouldShowFeedbackSection) {
                        setShouldShowFeedbackSection(false);
                        logUserEventUtil(apolloClient, {
                            type: 'closedFeedbackSectionOnJokoAi',
                            payload: { conversationId },
                        });
                    }
                }}
            />
            <MainContainer {...{ viewportHeight }}>
                <ConversationHistorySection
                    {...{
                        conversationId,
                        resetConversation,
                        setMessages,
                        inputTextRef,
                        shouldShowConversationHistory,
                        setShouldShowConversationHistory,
                        selectedConversationHistoryItemId,
                        setSelectedConversationHistoryItemId,
                        setFailedToFetchConversation,
                        setIsAssistantThinking,
                        lastConversationHistoryItem,
                        lastConversationTitle,
                    }}
                />
                <ContentContainer>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                        <DesktopHeader
                            {...{
                                shouldShowConversationHistory,
                                setShouldShowConversationHistory,
                                setShouldShowInfoTooltip,
                                shouldShowInfoTooltip,
                            }}
                        />
                        <FeedbackSection
                            {...{
                                shouldShowFeedbackSection,
                                setShouldShowFeedbackSection,
                                conversationId,
                            }}
                        />
                        <ConversationContainer {...{ viewportHeight }}>
                            {failedToFetchConversation ? (
                                <ErrorMessageOnFetchConversation />
                            ) : (
                                <>
                                    {messages === undefined ? (
                                        <LandingPageContentContainer>
                                            <LandingPageContent {...{ sendMessage, isInputBarFocused }} />
                                            <InputBar
                                                {...{
                                                    inputText,
                                                    setInputText,
                                                    inputTextRef,
                                                    setIsInputBarFocused,
                                                    sendMessage,
                                                    isAssistantThinking,
                                                    setBottomPositionScrollButton,
                                                    isLandingPage: true,
                                                }}
                                            />
                                        </LandingPageContentContainer>
                                    ) : (
                                        <DesktopMessagesSection
                                            {...{
                                                messages,
                                                messagesListRef,
                                                conversationId,
                                                selectedConversationHistoryItemId,
                                                inputText,
                                                setInputText,
                                                inputTextRef,
                                                setIsInputBarFocused,
                                                sendMessage,
                                                resetConversation,
                                                isAssistantThinking,
                                                setBottomPositionScrollButton,
                                                fetchMoreProductSuggestions,
                                                isFetchingMoreProductSuggestions,
                                                isFromSerpEmbedding,
                                                setShouldShowFeedbackSection,
                                            }}
                                        />
                                    )}
                                </>
                            )}
                        </ConversationContainer>
                    </div>
                </ContentContainer>
            </MainContainer>
        </>
    );
}

function usePreventWebpageScroll(shouldShowConversationHistory: boolean) {
    React.useEffect(() => {
        if (shouldShowConversationHistory) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = '';
    }, [shouldShowConversationHistory]);
}

const MainContainer = styled.div<{
    viewportHeight: number;
}>`
    display: flex;
    flex-direction: row;
    height: ${({ viewportHeight }) => viewportHeight}px;
    position: relative;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
`;

const ConversationContainer = styled.div<{
    viewportHeight: number;
}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: ${({ viewportHeight }) => `calc(${viewportHeight}px - 52px)`};
    align-items: center;
    background-color: ${colors.background.light};
`;

const LandingPageContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 45%;
    margin-top: 24px;
    padding-top: 112px;
    padding-left: 64px;
    padding-right: 64px;
    border-radius: 12px 12px 0px 0px;
    background-color: ${colors.background.default};

    @media (max-width: ${deviceWidthMeasurements.xLarge}) {
        width: 60%;
    }

    @media (max-width: ${deviceWidthMeasurements.large}) {
        width: 70%;
    }
`;
