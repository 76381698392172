import React from 'react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/react-hooks';

import { UserProductDiscoveryConversationHistoryItem } from '../../../api/graphql/fragments/productDiscovery';
import { EnrichedProductDiscoveryConversationMessage } from '../../../lib/productDiscovery/conversationMessages';
import { Overlay } from '../common/Overlay';
import { ConversationHistorySection } from '../common/ConversationHistorySection';
import { LandingPageContent } from '../common/LandingPageContent';
import { MobileMessagesSection } from './MobileMessagesSection';
import { InputBar } from '../common/InputBar';
import { ErrorMessageOnFetchConversation } from '../common/ErrorMessage';
import { useViewportSize } from '../../../style/utils';
import { useShouldShowConversationHistory } from '../../../lib/productDiscovery/conversationHistory';
import { MobileHeader } from './MobileHeader';
import { MobileAllProductSuggestions } from './MobileAllProductSuggestions';
import {
    FeedbackButton,
    FeedbackButtonIcon,
    FeedbackButtonText,
    InputBarAndFeedbackContainer,
} from '../common/FeedbackButton';
import { FeedbackSection } from '../common/FeedbackSection';
import { getLocalizedTexts } from '../../../Locales';
import { logUserEventUtil } from '../../../lib/events/userEvents';

const megaphoneIcon = '/assets/images/icons/megaphone.svg';

export function MobileProductDiscoveryPage({
    conversationId,
    resetConversation,
    messages,
    setMessages,
    messagesListRef,
    inputText,
    setInputText,
    selectedConversationHistoryItemId,
    setSelectedConversationHistoryItemId,
    failedToFetchConversation,
    setFailedToFetchConversation,
    sendMessage,
    isAssistantThinking,
    setIsAssistantThinking,
    lastConversationHistoryItem,
    lastConversationTitle,
    isFromSerpEmbedding,
    fetchMoreProductSuggestions,
    isFetchingMoreProductSuggestions,
}: {
    conversationId: string;
    resetConversation: ({
        conversationToReopen,
    }: {
        conversationToReopen?: {
            conversationId: string;
            messages: EnrichedProductDiscoveryConversationMessage[];
        };
    }) => void;
    messages: EnrichedProductDiscoveryConversationMessage[] | undefined;
    setMessages: (messages: EnrichedProductDiscoveryConversationMessage[] | undefined) => void;
    messagesListRef: React.RefObject<HTMLDivElement | null>;
    inputText: string;
    setInputText: (value: string) => void;
    selectedConversationHistoryItemId: string | undefined;
    setSelectedConversationHistoryItemId: (value: string | undefined) => void;
    failedToFetchConversation: boolean;
    setFailedToFetchConversation: (value: boolean) => void;
    sendMessage: (suggestedMessage?: string) => void;
    isAssistantThinking: boolean;
    setIsAssistantThinking: (value: boolean) => void;
    lastConversationHistoryItem: UserProductDiscoveryConversationHistoryItem | undefined;
    lastConversationTitle: string | undefined;
    isFromSerpEmbedding: boolean;
    fetchMoreProductSuggestions: (messageId: string, currentQueryScrollOffsets: number[] | undefined) => void;
    isFetchingMoreProductSuggestions: boolean;
}) {
    const { height: viewportHeight } = useViewportSize();
    const inputTextRef = React.useRef<HTMLTextAreaElement>(null);
    const { shouldShowConversationHistory, setShouldShowConversationHistory } = useShouldShowConversationHistory();
    const [shouldShowInfoTooltip, setShouldShowInfoTooltip] = React.useState(false);
    usePreventWebpageScroll(shouldShowConversationHistory);
    const [messageIndexToDisplayAllProductSuggestions, setMessageIndexToDisplayAllProductSuggestions] = React.useState<
        number | undefined
    >(undefined);
    const [shouldShowFeedbackSection, setShouldShowFeedbackSection] = React.useState(false);
    const shouldDisplayOverlay = shouldShowConversationHistory || shouldShowInfoTooltip || shouldShowFeedbackSection;
    const handleOverlayClick = () => {
        setShouldShowInfoTooltip(false);
        setShouldShowConversationHistory(false);
        setShouldShowFeedbackSection(false);
    };
    return (
        <>
            <Overlay {...{ isVisible: shouldDisplayOverlay }} onClick={handleOverlayClick} />
            <MainContainer {...{ viewportHeight }}>
                {messageIndexToDisplayAllProductSuggestions && messages ? (
                    <MobileAllProductSuggestions
                        {...{
                            messageIndexToDisplayAllProductSuggestions,
                            setMessageIndexToDisplayAllProductSuggestions,
                            shouldShowInfoTooltip,
                            setShouldShowInfoTooltip,
                            messages,
                            conversationId,
                            fetchMoreProductSuggestions,
                            isFetchingMoreProductSuggestions,
                        }}
                    />
                ) : null}
                <>
                    <ConversationHistorySection
                        {...{
                            conversationId,
                            resetConversation,
                            setMessages,
                            inputTextRef,
                            shouldShowConversationHistory,
                            setShouldShowConversationHistory,
                            selectedConversationHistoryItemId,
                            setSelectedConversationHistoryItemId,
                            setFailedToFetchConversation,
                            setIsAssistantThinking,
                            lastConversationHistoryItem,
                            lastConversationTitle,
                        }}
                    />
                    <ContentContainer>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                            <MobileHeader
                                {...{
                                    resetConversation,
                                    shouldShowConversationHistory,
                                    setShouldShowConversationHistory,
                                    inputTextRef,
                                }}
                            />
                            <FeedbackSection
                                {...{
                                    shouldShowFeedbackSection,
                                    setShouldShowFeedbackSection,
                                    conversationId,
                                }}
                            />
                            <MobileProductDiscoveryContent
                                {...{
                                    messages,
                                    messagesListRef,
                                    conversationId,
                                    selectedConversationHistoryItemId,
                                    isFromSerpEmbedding,
                                    setMessageIndexToDisplayAllProductSuggestions,
                                    failedToFetchConversation,
                                    sendMessage,
                                    inputText,
                                    setInputText,
                                    inputTextRef,
                                    isAssistantThinking,
                                    setShouldShowFeedbackSection,
                                }}
                            />
                        </div>
                    </ContentContainer>
                </>
            </MainContainer>
        </>
    );
}

function MobileProductDiscoveryContent({
    messages,
    messagesListRef,
    conversationId,
    selectedConversationHistoryItemId,
    isFromSerpEmbedding,
    setMessageIndexToDisplayAllProductSuggestions,
    failedToFetchConversation,
    sendMessage,
    inputText,
    setInputText,
    inputTextRef,
    isAssistantThinking,
    setShouldShowFeedbackSection,
}: {
    messages: EnrichedProductDiscoveryConversationMessage[] | undefined;
    messagesListRef: React.RefObject<HTMLDivElement | null>;
    conversationId: string;
    selectedConversationHistoryItemId: string | undefined;
    isFromSerpEmbedding: boolean;
    setMessageIndexToDisplayAllProductSuggestions: (value: number | undefined) => void;
    failedToFetchConversation: boolean;
    sendMessage: (suggestedMessage?: string) => void;
    inputText: string;
    setInputText: (value: string) => void;
    inputTextRef: React.RefObject<HTMLTextAreaElement>;
    isAssistantThinking: boolean;
    setShouldShowFeedbackSection: (value: boolean) => void;
}) {
    const apolloClient = useApolloClient();
    const [bottomPositionScrollButton, setBottomPositionScrollButton] = React.useState(0);
    const [isInputBarFocused, setIsInputBarFocused] = React.useState(false);
    const texts = getLocalizedTexts().productDiscovery.feedback;
    return !failedToFetchConversation ? (
        <>
            {messages === undefined ? (
                <>
                    <LandingPageContent {...{ sendMessage, isInputBarFocused }} />
                    <InputBar
                        {...{
                            inputText,
                            setInputText,
                            inputTextRef,
                            setIsInputBarFocused,
                            sendMessage,
                            isAssistantThinking,
                            setBottomPositionScrollButton,
                            isLandingPage: true,
                        }}
                    />
                </>
            ) : (
                <>
                    <MobileMessagesSection
                        {...{
                            messages,
                            messagesListRef,
                            conversationId,
                            selectedConversationHistoryItemId,
                            bottomPositionScrollButton,
                            isFromSerpEmbedding,
                            setMessageIndexToDisplayAllProductSuggestions,
                        }}
                    />
                    <InputBarAndFeedbackContainer>
                        <InputBar
                            {...{
                                inputText,
                                setInputText,
                                inputTextRef,
                                setIsInputBarFocused,
                                sendMessage,
                                isAssistantThinking,
                                setBottomPositionScrollButton,
                            }}
                        />
                        <FeedbackButton
                            onClick={() => {
                                setShouldShowFeedbackSection(true);
                                logUserEventUtil(apolloClient, {
                                    type: 'clickedFeedbackButtonOnJokoAi',
                                    payload: { conversationId },
                                });
                            }}>
                            <FeedbackButtonIcon src={megaphoneIcon} />
                            <FeedbackButtonText>{texts.buttonTitle}</FeedbackButtonText>
                        </FeedbackButton>
                    </InputBarAndFeedbackContainer>
                </>
            )}
        </>
    ) : (
        <ErrorMessageOnFetchConversation />
    );
}

function usePreventWebpageScroll(shouldShowConversationHistory: boolean) {
    React.useEffect(() => {
        if (shouldShowConversationHistory) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = '';
    }, [shouldShowConversationHistory]);
}

const MainContainer = styled.div<{
    viewportHeight: number;
}>`
    display: flex;
    flex-direction: row;
    height: ${({ viewportHeight }) => viewportHeight}px;
    position: relative;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
`;
