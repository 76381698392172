import React from 'react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/react-hooks';

import { logUserEventUtil } from '../../../lib/events/userEvents';
import { HeaderImage, HeaderImageContainer } from './Header';
import { colors } from '../../../tokens/colors/colors';
import { getLocalizedTexts } from '../../../Locales';
import { deviceWidthMeasurements } from '../../../style/size';
import { font } from '../../../style/text';
import { isMobileDevice } from '../../../style/utils';
import { CLICKABLE_ELEMENT_CSS_MIXIN } from '../../../style/styleConstants';

const infoIcon = '/assets/images/icons/info.svg';
const closeIcon = '/assets/images/icons/cross-grey-slim.svg';

export function InfoTooltip({
    shouldShowInfoTooltip,
    setShouldShowInfoTooltip,
}: {
    shouldShowInfoTooltip: boolean;
    setShouldShowInfoTooltip: (value: boolean) => void;
}) {
    const apolloClient = useApolloClient();
    const infoTooltipRef = React.useRef<HTMLDivElement | null>(null);
    const infoButtonRef = React.useRef<HTMLDivElement | null>(null);
    useCloseInfoToolTipOnDesktop({
        infoTooltipRef,
        infoButtonRef,
        shouldShowInfoTooltip,
        setShouldShowInfoTooltip,
    });
    const texts = getLocalizedTexts().productDiscovery;
    return (
        <>
            <HeaderImageContainer
                ref={infoButtonRef}
                onClick={() => {
                    setShouldShowInfoTooltip(!shouldShowInfoTooltip);
                    if (!shouldShowInfoTooltip)
                        logUserEventUtil(apolloClient, {
                            type: 'clickedInfoButtonOnJokoAi',
                        });
                }}>
                <HeaderImage src={infoIcon} draggable={false} />
            </HeaderImageContainer>
            {shouldShowInfoTooltip ? (
                isMobileDevice ? (
                    <MobileInfoTooltip>
                        <CloseIcon src={closeIcon} onClick={() => setShouldShowInfoTooltip(!shouldShowInfoTooltip)} />
                        <InfoTooltipTitle>{texts.infoTooltip.title}</InfoTooltipTitle>
                        <InfoTooltipText>{texts.infoTooltip.content}</InfoTooltipText>
                    </MobileInfoTooltip>
                ) : (
                    <DesktopInfoTooltip ref={infoTooltipRef}>
                        <InfoTooltipTitle>{texts.infoTooltip.title}</InfoTooltipTitle>
                        <InfoTooltipText>{texts.infoTooltip.content}</InfoTooltipText>
                    </DesktopInfoTooltip>
                )
            ) : null}
        </>
    );
}

function useCloseInfoToolTipOnDesktop({
    infoTooltipRef,
    infoButtonRef,
    shouldShowInfoTooltip,
    setShouldShowInfoTooltip,
}: {
    infoTooltipRef: React.RefObject<HTMLElement>;
    infoButtonRef: React.RefObject<HTMLElement>;
    shouldShowInfoTooltip: boolean;
    setShouldShowInfoTooltip: (value: boolean) => void;
}): void {
    React.useEffect(() => {
        if (isMobileDevice) return;
        const handleClickOutside = (event: MouseEvent) => {
            if (
                !infoTooltipRef.current?.contains(event.target as Node) &&
                !infoButtonRef.current?.contains(event.target as Node)
            )
                setShouldShowInfoTooltip(false);
        };
        if (shouldShowInfoTooltip) document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [shouldShowInfoTooltip]);
}

const DesktopInfoTooltip = styled.div`
    position: absolute;
    z-index: 2;
    top: 70px;
    right: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 442px;
    padding: 32px;
    border-radius: 20px;
    box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
    background-color: ${colors.background.default};

    /* This creates the triangle at the top of the tooltip by using a rotated square. */
    &::before {
        position: absolute;
        bottom: 93%;
        left: 91%;
        content: ''; /* This property is required by the pseudo-element in order to render */
        width: 21px;
        height: 21px;
        border-radius: 4px 0 0 0;
        box-shadow: -6px -6px 15px -4px rgba(0, 0, 0, 0.1);
        background-color: ${colors.background.default};
        transform: translateX(-50%);
        transform: rotate(45deg);

        @media (max-width: ${deviceWidthMeasurements.small}) {
            left: 82%;
        }
    }

    @media (max-width: ${deviceWidthMeasurements.small}) {
        width: 250px;
        top: 65px;
        right: 15px;
    }
`;

const MobileInfoTooltip = styled.div`
    position: fixed;
    z-index: 2;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 24px 16px;
    border-radius: 20px;
    box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
    background-color: ${colors.background.default};
    transform: translate(-50%, -50%);
`;

const CloseIcon = styled.img`
    position: absolute;
    top: 12px;
    right: 12px;
    width: 16px;
    height: 16px;
    text-align: right;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}
`;

const InfoTooltipTitle = styled.div`
    margin-bottom: 4px;
    font-family: ${font.ambitBold};
    font-size: 22px;
    color: ${colors.content.default};
`;

const InfoTooltipText = styled.div`
    font-family: ${font.ambitRegular};
    font-size: 16px;
    color: ${colors.content.secondary};
`;
