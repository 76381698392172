import React from 'react';
import styled from 'styled-components';
import { CLICKABLE_ELEMENT_CSS_MIXIN } from '../../../style/styleConstants';
import { EnrichedProductDiscoveryConversationMessage } from '../../../lib/productDiscovery/conversationMessages';
import { colors } from '../../../tokens/colors/colors';
import { logUserEventUtil } from '../../../lib/events/userEvents';
import { useApolloClient } from '@apollo/react-hooks';
import { JokoAiBetaTitle } from '../common/JokoAiBetaTitle';

const sidebar = '/assets/images/icons/sidebar.svg';
const editIcon = '/assets/images/icons/edit.svg';

export function MobileHeader({
    resetConversation,
    shouldShowConversationHistory,
    setShouldShowConversationHistory,
    inputTextRef,
}: {
    resetConversation: ({
        conversationToReopen,
    }: {
        conversationToReopen?: {
            conversationId: string;
            messages: EnrichedProductDiscoveryConversationMessage[];
        };
    }) => void;
    shouldShowConversationHistory: boolean;
    setShouldShowConversationHistory: (value: boolean) => void;
    inputTextRef: React.RefObject<HTMLTextAreaElement | null>;
}) {
    const apolloClient = useApolloClient();
    return (
        <HeaderContainer>
            <HeaderImage
                src={sidebar}
                draggable={false}
                onClick={() => setShouldShowConversationHistory(!shouldShowConversationHistory)}
            />
            <JokoAiBetaTitle />
            <HeaderImage
                src={editIcon}
                draggable={false}
                onClick={() => {
                    resetConversation({});
                    inputTextRef.current?.focus();
                    logUserEventUtil(apolloClient, {
                        type: 'clickedNewConversationButtonOnJokoAi',
                    });
                }}
            />
        </HeaderContainer>
    );
}

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 4px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${colors.border.subtle};);
`;

const HeaderImage = styled.img`
    width: 24px;
    height: 24px;
    padding: 12px;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}
`;
