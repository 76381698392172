import { DEVICE_ID_LOCAL_STORAGE_KEY } from './device';
import { DEV_STACK_MODE_LOCAL_STORAGE_KEY } from './devStackMode';

const LOCAL_STORAGE_KEYS_TO_KEEP = [DEV_STACK_MODE_LOCAL_STORAGE_KEY, DEVICE_ID_LOCAL_STORAGE_KEY];

export function clearLocalStorageSafe(): void {
    const localStorageKeys = Object.keys(localStorage);
    for (const key of localStorageKeys) if (!LOCAL_STORAGE_KEYS_TO_KEEP.includes(key)) localStorage.removeItem(key);
}

export enum LocalStorageKey {
    userFeaturesMapLastFetchedTimestampMs = 'userFeaturesMapLastFetchedTimestampMs',
    userFeaturesMap = 'userFeaturesMap',
}
