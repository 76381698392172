import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/react-hooks';

import { getLocalizedTexts } from '../../../Locales';
import { ApolloClient } from '../../../api/graphql/client';
import {
    MerchantProductOffer,
    EnrichedProductDiscoveryConversationMessage,
} from '../../../lib/productDiscovery/conversationMessages';
import { font } from '../../../style/text';
import { colors } from '../../../tokens/colors/colors';
import { NO_SCROLLBAR_CSS_MIXIN } from '../../../style/styleConstants';
import GradientText from '../../../components/common/GradientText';
import { MerchantProductOfferCard } from '../common/MerchantProductOfferCard';
import { ScrollToBottomButton } from '../common/ScrollToBottomButton';
import { useLogViewedConversationInterfaceOnJokoAi } from '../../../lib/productDiscovery/events';
import {
    MerchantIdToMerchantNameMap,
    useMerchantIdToMerchantNameMap,
    useHandleScroll,
} from '../../../lib/productDiscovery/displayLogic';

const logo = '/assets/images/logos/lightning-round.svg';
const rightArrow = '/assets/images/icons/right-arrow.svg';

export function MobileMessagesSection({
    messages,
    messagesListRef,
    conversationId,
    selectedConversationHistoryItemId,
    bottomPositionScrollButton,
    isFromSerpEmbedding,
    setMessageIndexToDisplayAllProductSuggestions,
}: {
    messages: EnrichedProductDiscoveryConversationMessage[];
    messagesListRef: React.MutableRefObject<HTMLDivElement | null>;
    conversationId: string;
    selectedConversationHistoryItemId: string | undefined;
    bottomPositionScrollButton: number;
    isFromSerpEmbedding: boolean;
    setMessageIndexToDisplayAllProductSuggestions: (value: number | undefined) => void;
}) {
    const apolloClient = useApolloClient();
    useLogViewedConversationInterfaceOnJokoAi({
        conversationId,
        selectedConversationHistoryItemId,
        isFromSerpEmbedding,
    });
    const merchantIdToMerchantNameMap = useMerchantIdToMerchantNameMap({ messages });
    const { isAtBottom, onScroll } = useHandleScroll({
        listRef: messagesListRef,
        listContent: messages,
        shouldAutoScrollToBottom: true,
        scrollBehavior: 'auto',
    });
    const renderMessage = getRenderMessage({
        apolloClient,
        merchantIdToMerchantNameMap,
        conversationId,
        setMessageIndexToDisplayAllProductSuggestions,
    });
    return (
        <>
            <MessagesContainer ref={messagesListRef} onScroll={onScroll}>
                <MessagesInnerContainer>
                    {messages.map((message) => renderMessage({ item: message }))}
                </MessagesInnerContainer>
            </MessagesContainer>
            {!isAtBottom ? <ScrollToBottomButton {...{ listRef: messagesListRef, bottomPositionScrollButton }} /> : null}
        </>
    );
}

function getRenderMessage({
    apolloClient,
    merchantIdToMerchantNameMap,
    conversationId,
    setMessageIndexToDisplayAllProductSuggestions,
}: {
    apolloClient: ApolloClient | undefined;
    merchantIdToMerchantNameMap: MerchantIdToMerchantNameMap;
    conversationId: string;
    setMessageIndexToDisplayAllProductSuggestions: (value: number | undefined) => void;
}): React.FC<{ item: EnrichedProductDiscoveryConversationMessage }> {
    return ({ item: message }) => {
        if (message.role === 'user') return <UserMessage {...{ message }} key={message.messageIndex} />;
        return (
            <AssistantMessage
                {...{
                    message,
                    apolloClient,
                    merchantIdToMerchantNameMap,
                    conversationId,
                    setMessageIndexToDisplayAllProductSuggestions,
                }}
                key={message.messageIndex}
            />
        );
    };
}

function UserMessage({ message }: { message: EnrichedProductDiscoveryConversationMessage }) {
    return (
        <UserMessageContainer {...{ isFirstMessage: message.messageIndex === 0 }}>
            <MessageUserText>{message.content}</MessageUserText>
        </UserMessageContainer>
    );
}

function AssistantMessage({
    message,
    apolloClient,
    merchantIdToMerchantNameMap,
    conversationId,
    setMessageIndexToDisplayAllProductSuggestions,
}: {
    message: EnrichedProductDiscoveryConversationMessage;
    apolloClient: ApolloClient | undefined;
    merchantIdToMerchantNameMap: MerchantIdToMerchantNameMap;
    conversationId: string;
    setMessageIndexToDisplayAllProductSuggestions: (value: number | undefined) => void;
}) {
    const texts = getLocalizedTexts();
    return (
        <AssistantMessageContainer>
            <AssistantMessageTextContainer>
                <JokoLogoMessageImage src={logo} />
                {message.content ? (
                    <MessageAssistantText>
                        <FormattedMessage>{message.content}</FormattedMessage>
                    </MessageAssistantText>
                ) : (
                    // TODO: refactor to remove React Native components
                    <GradientText text={texts.productDiscovery.thinkingPlaceholder} fontSize={16} />
                )}
            </AssistantMessageTextContainer>
            {message.merchantProductOffers?.length ? (
                <SuggestedMerchantProductOffersPreview
                    {...{
                        offers: message.merchantProductOffers.slice(0, 4),
                        merchantIdToMerchantNameMap,
                        conversationId,
                        messageIndex: message.messageIndex,
                        setMessageIndexToDisplayAllProductSuggestions,
                    }}
                />
            ) : null}
        </AssistantMessageContainer>
    );
}

function SuggestedMerchantProductOffersPreview({
    offers,
    merchantIdToMerchantNameMap,
    conversationId,
    messageIndex,
    setMessageIndexToDisplayAllProductSuggestions,
}: {
    offers: MerchantProductOffer[];
    merchantIdToMerchantNameMap: MerchantIdToMerchantNameMap;
    conversationId: string;
    messageIndex: number;
    setMessageIndexToDisplayAllProductSuggestions: (value: number | undefined) => void;
}) {
    return (
        <SuggestedMerchantProductOffersPreviewContainer>
            {offers.map((offer) => (
                <MerchantProductOfferCard
                    key={offer.merchantProductOfferId}
                    {...{
                        offer,
                        merchantIdToMerchantNameMap,
                        conversationId,
                        messageId: `${conversationId}|${messageIndex}`,
                    }}
                />
            ))}
            <ViewAllResultsCard {...{ messageIndex, setMessageIndexToDisplayAllProductSuggestions }} />
        </SuggestedMerchantProductOffersPreviewContainer>
    );
}

function ViewAllResultsCard({
    messageIndex,
    setMessageIndexToDisplayAllProductSuggestions,
}: {
    messageIndex: number;
    setMessageIndexToDisplayAllProductSuggestions: (value: number | undefined) => void;
}) {
    const texts = getLocalizedTexts().productDiscovery;
    return (
        <ViewAllResultsCardContainer onClick={() => setMessageIndexToDisplayAllProductSuggestions(messageIndex)}>
            <CircleContainer>
                <Circle>
                    <SeeAllResultsIcon src={rightArrow} draggable={false} />
                </Circle>
            </CircleContainer>
            <SeeAllResultsText>{texts.seeAllResults}</SeeAllResultsText>
        </ViewAllResultsCardContainer>
    );
}

const MessagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    width: calc(100% - 32px);
    margin-top: 16px;
    padding: 0 16px;
    overflow-x: hidden;
    overflow-y: auto;

    ${NO_SCROLLBAR_CSS_MIXIN}
`;

const MessagesInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const UserMessageContainer = styled.div<{ isFirstMessage: boolean }>`
    max-width: 256px;
    align-self: flex-end;
    padding: 16px 24px 16px 24px;
    margin-top: ${({ isFirstMessage }) => (isFirstMessage ? '0px' : '24px')};
    margin-bottom: 24px;
    border-radius: 12px;
    background-color: ${colors.background.light};
`;

const AssistantMessageContainer = styled.div`
    flex-direction: column;
    align-self: flex-start;
`;

const AssistantMessageTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    max-width: calc(100vw - 32px);
    align-items: flex-start;
`;

const MessageUserText = styled.div`
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    line-height: 21px;
`;

const MessageAssistantText = styled.div`
    margin-top: 4px;
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    line-height: 22px;
`;

// ReactMarkdown wraps text content in <p> tags, which typically have a default margin applied by browsers.
// This is a workaround to remove the margin.
const FormattedMessage = styled(ReactMarkdown)`
    p {
        margin: 0;
    }
`;

const SuggestedMerchantProductOffersPreviewContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    gap: 8px;
    width: calc(100vw - 100px);
    padding-left: 40px;
    padding-right: 16px;
    margin-top: 24px;
    margin-bottom: 18px;
    overflow-x: auto;

    ${NO_SCROLLBAR_CSS_MIXIN}
`;

const ViewAllResultsCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 168px;
    max-width: 168px;
    padding: 16px;
    border: 1px solid ${colors.border.default};
    border-radius: 20px;
    cursor: pointer;
`;

const CircleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 168px;
    width: 136px;
    height: 136px;
    margin-bottom: 16px;
`;
const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border: 1px solid ${colors.border.default};
    border-radius: 50%;
`;

const SeeAllResultsText = styled.div`
    max-width: 136px;
    font-family: ${font.ambitSemiBold};
    font-size: 16px;
    text-align: center;
    white-space: normal;
    line-height: 120%;
`;

const JokoLogoMessageImage = styled.img`
    width: 28px;
    height: 28px;
    margin-right: 12px;
    flex-shrink: 0; // Prevent the logo from shrinking with the text
`;

export const SeeAllResultsIcon = styled.img`
    width: 16px;
    height: 16px;
`;
