import React from 'react';
import styled from 'styled-components';

import { UserProductDiscoveryConversationHistoryItem } from '../../../api/graphql/fragments/productDiscovery';
import { EnrichedProductDiscoveryConversationMessage } from '../../../lib/productDiscovery/conversationMessages';
import {
    useFetchMessagesOnConversationReopen,
    useConversationHistoryItems,
    ConversationHistoryItemsByDate,
} from '../../../lib/productDiscovery/conversationHistory';
import { font } from '../../../style/text';
import { isMobileDevice, MAX_MOBILE_SCREEN_WIDTH } from '../../../style/utils';
import { colors } from '../../../tokens/colors/colors';
import { getLocalizedTexts } from '../../../Locales';
import { SpinningWheel } from '../../../components/common/SpinningWheel';
import { Overlay } from './Overlay';
import { ConversationHistoryItem } from './ConversationsHistoryItem';
import { ConversationHistorySectionEmptyState } from './ConversationHistorySectionEmptyState';
import { DeleteConversationHistoryConfirmationModal } from './DeleteConversationHistoryConfirmationModal';
import { useApolloClient } from '@apollo/react-hooks';
import { HeaderImage, HeaderImageContainer } from '../desktop/DesktopHeader';
import { logUserEventUtil } from '../../../lib/events/userEvents';
import { JokoAiBetaTitle } from './JokoAiBetaTitle';

const editIcon = '/assets/images/icons/edit.svg';
const closeConversationHistoryIcon = '/assets/images/icons/cross-black-slim.svg';
const deleteHistoryIcon = '/assets/images/icons/bin.svg';

export function ConversationHistorySection({
    conversationId: currentConversationId,
    resetConversation,
    setMessages,
    inputTextRef,
    shouldShowConversationHistory,
    setShouldShowConversationHistory,
    selectedConversationHistoryItemId,
    setSelectedConversationHistoryItemId,
    setFailedToFetchConversation,
    setIsAssistantThinking,
    lastConversationHistoryItem,
    lastConversationTitle,
}: {
    conversationId: string;
    resetConversation: ({
        conversationToReopen,
    }: {
        conversationToReopen?: {
            conversationId: string;
            messages: EnrichedProductDiscoveryConversationMessage[];
        };
    }) => void;
    setMessages: (messages: EnrichedProductDiscoveryConversationMessage[] | undefined) => void;
    inputTextRef: React.RefObject<HTMLTextAreaElement | null>;
    shouldShowConversationHistory: boolean;
    setShouldShowConversationHistory: (value: boolean) => void;
    selectedConversationHistoryItemId: string | undefined;
    setSelectedConversationHistoryItemId: (value: string | undefined) => void;
    setFailedToFetchConversation: (value: boolean) => void;
    setIsAssistantThinking: (value: boolean) => void;
    lastConversationHistoryItem: UserProductDiscoveryConversationHistoryItem | undefined;
    lastConversationTitle: string | undefined;
}) {
    const {
        conversationHistoryItemsByDate,
        setConversationHistoryItemsByDate,
        fetchMore,
        checkCanFetchMore,
        isFetchingMore,
    } = useConversationHistoryItems(lastConversationHistoryItem);
    useFetchMessagesOnConversationReopen({
        selectedConversationHistoryItemId,
        resetConversation,
        inputTextRef,
        setFailedToFetchConversation,
        setIsAssistantThinking,
    });
    const [shouldShowDeleteHistoryConfirmation, setShouldShowDeleteHistoryConfirmation] = React.useState(false);
    const [conversationToDeleteOnMobile, setConversationToDeleteOnMobile] = React.useState<string | undefined>(undefined);
    return (
        <ConversationHistorySectionContainer {...{ shouldShowConversationHistory, isMobileDevice }}>
            {shouldShowDeleteHistoryConfirmation ? (
                <DeleteConversationHistoryConfirmationModal
                    {...{
                        setShouldShowDeleteHistoryConfirmation,
                        setConversationHistoryItemsByDate,
                        resetConversation,
                    }}
                />
            ) : null}
            <Overlay
                isVisible={shouldShowDeleteHistoryConfirmation || conversationToDeleteOnMobile !== undefined}
                onClick={() => {
                    setShouldShowDeleteHistoryConfirmation(false);
                    setConversationToDeleteOnMobile(undefined);
                }}
            />
            <ConversationsHistoryHeader
                {...{ resetConversation, shouldShowConversationHistory, setShouldShowConversationHistory, inputTextRef }}
            />
            <ConversationHistoryContent
                {...{
                    currentConversationId,
                    selectedConversationHistoryItemId,
                    setSelectedConversationHistoryItemId,
                    lastConversationTitle,
                    setMessages,
                    setFailedToFetchConversation,
                    conversationToDeleteOnMobile,
                    setConversationToDeleteOnMobile,
                    resetConversation,
                    setShouldShowDeleteHistoryConfirmation,
                    conversationHistoryItemsByDate,
                    setConversationHistoryItemsByDate,
                    checkCanFetchMore,
                    fetchMore,
                    isFetchingMore,
                }}
            />
        </ConversationHistorySectionContainer>
    );
}

function ConversationHistoryContent({
    currentConversationId,
    selectedConversationHistoryItemId,
    setSelectedConversationHistoryItemId,
    lastConversationTitle,
    setMessages,
    setFailedToFetchConversation,
    conversationToDeleteOnMobile,
    setConversationToDeleteOnMobile,
    resetConversation,
    setShouldShowDeleteHistoryConfirmation,
    conversationHistoryItemsByDate,
    setConversationHistoryItemsByDate,
    checkCanFetchMore,
    fetchMore,
    isFetchingMore,
}: {
    currentConversationId: string;
    selectedConversationHistoryItemId: string | undefined;
    setSelectedConversationHistoryItemId: (value: string | undefined) => void;
    lastConversationTitle: string | undefined;
    setMessages: (messages: EnrichedProductDiscoveryConversationMessage[] | undefined) => void;
    setFailedToFetchConversation: (value: boolean) => void;
    conversationToDeleteOnMobile: string | undefined;
    setConversationToDeleteOnMobile: (value: string | undefined) => void;
    resetConversation: ({
        conversationToReopen,
    }: {
        conversationToReopen?: {
            conversationId: string;
            messages: EnrichedProductDiscoveryConversationMessage[];
        };
    }) => void;
    setShouldShowDeleteHistoryConfirmation: (value: boolean) => void;
    conversationHistoryItemsByDate: ConversationHistoryItemsByDate | undefined;
    setConversationHistoryItemsByDate: (value: ConversationHistoryItemsByDate) => void;
    checkCanFetchMore: () => boolean;
    fetchMore: () => void;
    isFetchingMore: boolean;
}) {
    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        if (scrollHeight - scrollTop - clientHeight <= 10 && checkCanFetchMore()) fetchMore();
    };
    const texts = getLocalizedTexts().productDiscovery.conversationHistory;
    if (conversationHistoryItemsByDate === undefined)
        return (
            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                <SpinningWheel {...{ width: 18, height: 18 }} />
            </div>
        );
    if (!conversationHistoryItemsByDate.length) return <ConversationHistorySectionEmptyState />;
    return (
        <>
            <ConversationsHistoryContainer onScroll={handleScroll}>
                {conversationHistoryItemsByDate.map(({ date, conversations }, index) => {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }} key={date}>
                            <ConversationsHistoryDate>
                                <ConversationsHistoryText style={{ fontFamily: font.ambitBold }}>
                                    {date}
                                </ConversationsHistoryText>
                            </ConversationsHistoryDate>
                            {conversations.map((conversation) => {
                                return (
                                    <ConversationHistoryItem
                                        {...{
                                            currentConversationId,
                                            conversation,
                                            selectedConversationHistoryItemId,
                                            setSelectedConversationHistoryItemId,
                                            lastConversationTitle,
                                            setMessages,
                                            setFailedToFetchConversation,
                                            conversationToDeleteOnMobile,
                                            setConversationToDeleteOnMobile,
                                            conversationHistoryItemsByDate,
                                            setConversationHistoryItemsByDate,
                                            resetConversation,
                                        }}
                                    />
                                );
                            })}
                            {index === conversationHistoryItemsByDate.length - 1 ? (
                                isFetchingMore ? (
                                    <div style={{ marginTop: 16, marginBottom: 16 }}>
                                        <SpinningWheel {...{ width: 18, height: 18 }} />
                                    </div>
                                ) : null
                            ) : (
                                <ConversationsHistorySeparator />
                            )}
                        </div>
                    );
                })}
            </ConversationsHistoryContainer>
            <ConversationHistoryFooter onClick={() => setShouldShowDeleteHistoryConfirmation(true)}>
                <DeleteHistoryButton>
                    <DeleteHistoryIcon src={deleteHistoryIcon} />
                    {texts.deleteHistory.deleteButton}
                </DeleteHistoryButton>
            </ConversationHistoryFooter>
        </>
    );
}

export function ConversationsHistoryHeader({
    shouldShowConversationHistory,
    setShouldShowConversationHistory,
    resetConversation,
    inputTextRef,
}: {
    shouldShowConversationHistory: boolean;
    setShouldShowConversationHistory: (value: boolean) => void;
    resetConversation: ({
        conversationToReopen,
    }: {
        conversationToReopen?: {
            conversationId: string;
            messages: EnrichedProductDiscoveryConversationMessage[];
        };
    }) => void;
    inputTextRef: React.RefObject<HTMLTextAreaElement | null>;
}) {
    const apolloClient = useApolloClient();
    const texts = getLocalizedTexts().productDiscovery.conversationHistory;
    return (
        <ConversationsHistoryHeaderContainer {...{ isMobileDevice }}>
            {shouldShowConversationHistory && (
                <>
                    {isMobileDevice ? (
                        <JokoAiBetaTitle />
                    ) : (
                        <>
                            <HeaderImageContainer>
                                <HeaderImage
                                    src={closeConversationHistoryIcon}
                                    draggable={false}
                                    onClick={() => setShouldShowConversationHistory(!shouldShowConversationHistory)}
                                />
                            </HeaderImageContainer>
                            <Title>{texts.menu}</Title>
                        </>
                    )}

                    <HeaderImageContainer>
                        <HeaderImage
                            src={editIcon}
                            draggable={false}
                            onClick={() => {
                                resetConversation({});
                                if (isMobileDevice) setShouldShowConversationHistory(false);
                                inputTextRef.current?.focus();
                                logUserEventUtil(apolloClient, {
                                    type: 'clickedNewConversationButtonOnJokoAi',
                                });
                            }}
                        />
                    </HeaderImageContainer>
                </>
            )}
        </ConversationsHistoryHeaderContainer>
    );
}

const MOBILE_PANEL_WIDTH = 300;

const ConversationsHistoryHeaderContainer = styled.div<{ isMobileDevice: boolean }>`
    display: flex;
    width: 100%;
    min-height: ${({ isMobileDevice }) => (isMobileDevice ? '57px' : '49px')};
    justify-content: space-between;
    align-items: center;
    padding-right: 8px;
    padding-left: ${({ isMobileDevice }) => (isMobileDevice ? '16px' : '8px')};
    border-bottom: 1px solid ${colors.border.subtle};
    box-sizing: border-box;
`;

const Title = styled.div`
    font-family: ${font.ambitBold};
    font-size: 18px;
    color: ${colors.content.default};
    white-space: nowrap;
`;

const ConversationHistorySectionContainer = styled.div<{
    shouldShowConversationHistory: boolean;
    isMobileDevice: boolean;
}>`
    display: flex;
    position: absolute;
    z-index: 4;
    flex-direction: column;
    flex: 0 0 auto;
    align-items: center;
    overflow-x: hidden;
    width: ${({ shouldShowConversationHistory }) =>
        shouldShowConversationHistory ? (isMobileDevice ? `${MOBILE_PANEL_WIDTH}px` : `26%`) : '0px'};
    height: 100%;
    transition: width 0.3s;
    background-color: ${colors.background.default};

    @media (max-width: ${MAX_MOBILE_SCREEN_WIDTH}) {
        background-color: ${colors.background.light};
    }

    @media (max-width: 1500px) {
        width: ${({ shouldShowConversationHistory }) =>
            shouldShowConversationHistory ? (isMobileDevice ? `${MOBILE_PANEL_WIDTH}px` : '412px') : '0px'};
    }

    @media (max-width: 900px) {
        width: ${({ shouldShowConversationHistory }) =>
            shouldShowConversationHistory ? (isMobileDevice ? `${MOBILE_PANEL_WIDTH}px` : '352px') : '0px'};
    }

    @media (max-width: 650px) {
        width: ${({ shouldShowConversationHistory }) =>
            shouldShowConversationHistory ? (isMobileDevice ? `${MOBILE_PANEL_WIDTH}px` : '332px') : '0px'};
    }
`;

const ConversationHistoryFooter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    padding: 16px;
    border-top: 1px solid ${colors.border.subtle};
    box-sizing: border-box;
`;

const DeleteHistoryButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${font.ambitSemiBold};
    font-size: 16px;
    color: ${colors.content.default};
    white-space: nowrap;

    &:hover {
        cursor: pointer;
    }
`;

const ConversationsHistoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 1;
    width: 100%;
    padding-top: 8px;
    scrollbar-color: ${colors.border.secondary} ${colors.background.default};
`;

const ConversationsHistoryDate = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
`;

const ConversationsHistorySeparator = styled.div`
    height: 1px;
    margin-top: 8px;
    background-color: ${colors.border.subtle};
`;

const ConversationsHistoryText = styled.div`
    display: -webkit-box;
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    color: ${colors.content.default};
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

const DeleteHistoryIcon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 8px;
`;
