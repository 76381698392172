import styled from 'styled-components';
import { colors } from '../../../tokens/theme';

export const Overlay = styled.div<{ isVisible: boolean; isHeaderVisible?: boolean }>`
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    position: fixed;
    top: ${({ isHeaderVisible }) => (isHeaderVisible ? '49px' : '0')};
    left: 0;
    width: 100%;
    z-index: 2;
    height: 100%;
    opacity: ${({ isVisible }) => (isVisible ? 0.3 : 0)};
    background: ${colors.background.primary};
    pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
`;
