import styled from 'styled-components';
import { colors } from '../../../tokens/colors/colors';
import { font } from '../../../style/text';
import { productDiscoveryContentWidths } from './constants';
import { isMobileDevice } from '../../../style/utils';
import { CLICKABLE_ELEMENT_CSS_MIXIN } from '../../../style/styleConstants';

export const FeedbackButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: calc(${productDiscoveryContentWidths.xxLarge} - 16px - 24px);
    width: calc(100% - 32px);
    height: 32px;
    padding: 12px;
    gap: 4px;
    margin: 0 auto;
    border: 1px solid ${colors.background.primary};
    border-radius: 96px;
    background-color: ${colors.background.secondary};
    ${CLICKABLE_ELEMENT_CSS_MIXIN}

    &:hover {
        background-color: ${colors.background.subtle};
    }
`;

export const FeedbackButtonText = styled.div`
    font-family: ${font.ambitSemiBold};
    font-size: 13px;
    color: ${colors.content.default};
`;
export const FeedbackButtonIcon = styled.img`
    width: 16px;
    height: 16px;
`;
export const InputBarAndFeedbackContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: ${isMobileDevice ? '12px 0px' : '0'};
`;
