import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';

import { logUserEventUtil } from '../events/userEvents';

export function useLogViewedConversationInterfaceOnJokoAi({
    conversationId,
    selectedConversationHistoryItemId,
    isFromSerpEmbedding,
}: {
    conversationId: string;
    selectedConversationHistoryItemId: string | undefined;
    isFromSerpEmbedding: boolean;
}) {
    const apolloClient = useApolloClient();
    React.useEffect(() => {
        // These conditions are to avoid logging the event several times when the user browses a past conversation
        if ((!selectedConversationHistoryItemId && !conversationId) || (conversationId && selectedConversationHistoryItemId))
            return;
        logUserEventUtil(apolloClient, {
            type: 'viewedConversationInterfaceOnJokoAi',
            payload: {
                conversationId: selectedConversationHistoryItemId ?? conversationId,
                isFromSerpEmbedding,
            },
        });
    }, [conversationId, selectedConversationHistoryItemId]);
}
