import React from "react";
import styled from "styled-components";

import { font } from "../../../style/text";
import { colors } from "../../../tokens/colors/colors";


export function JokoAiBetaTitle() {
    return (
        <TitleContainer>
            <Title>{'Joko AI'}</Title>
            <BetaTag>{'Beta'}</BetaTag>
        </TitleContainer>
    );
}

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
`;

const Title = styled.div`
    margin-right: 8px;
    color: ${colors.content.default};
    text-align: center;
    font-family: ${font.ambitBold};
    font-size: 18px;
`;

const BetaTag = styled.div`
    padding: 4px 8px;
    border: 1px solid ${colors.border.default};
    border-radius: 4px;
    font-family: ${font.ambitRegular};
    font-size: 14px;
    color: ${colors.content.default};
`;