import React from 'react';
import styled from 'styled-components';

import {
    CLICKABLE_ELEMENT_CSS_MIXIN,
    NON_HIGHLIGHTABLE_NOR_DRAGGABLE_ELEMENT_CSS_MIXIN,
} from '../../../style/styleConstants';
import { colors } from '../../../tokens/colors/colors';
import { InfoTooltip } from '../common/InfoTooltip';

const jokoLogo = '/assets/images/logos/logo-with-title.png';
const sidebar = '/assets/images/icons/sidebar.svg';

export function DesktopHeader({
    shouldShowConversationHistory,
    setShouldShowConversationHistory,
    shouldShowInfoTooltip,
    setShouldShowInfoTooltip,
}: {
    shouldShowConversationHistory: boolean;
    setShouldShowConversationHistory: (value: boolean) => void;
    shouldShowInfoTooltip: boolean;
    setShouldShowInfoTooltip: (value: boolean) => void;
}) {
    return (
        <HeaderContainer>
            <HeaderImageContainer>
                <HeaderImage
                    src={sidebar}
                    draggable={false}
                    onClick={() => setShouldShowConversationHistory(!shouldShowConversationHistory)}
                />
            </HeaderImageContainer>
            <JokoLogo src={jokoLogo} draggable={false} />
            <InfoTooltip {...{ shouldShowInfoTooltip, setShouldShowInfoTooltip }} />
        </HeaderContainer>
    );
}

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0px 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${colors.border.subtle};
`;

export const HeaderImageContainer = styled.div`
    display: flex;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    border-radius: 96px;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}

    &:active {
        background-color: ${colors.background.subtle};
    }

    &:hover {
        background-color: ${colors.background.subtle};
        cursor: pointer;
    }
`;

export const HeaderImage = styled.img`
    width: 24px;
    height: 24px;
    padding: 12px;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}
`;

const JokoLogo = styled.img`
    width: 94px;
    object-fit: contain;
    ${NON_HIGHLIGHTABLE_NOR_DRAGGABLE_ELEMENT_CSS_MIXIN}
`;
