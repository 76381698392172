import React from 'react';
import styled from 'styled-components';

import { getLocalizedTexts } from '../../../Locales';
import { CLICKABLE_ELEMENT_CSS_MIXIN } from '../../../style/styleConstants';
import { deviceWidthMeasurements } from '../../../style/size';
import { font } from '../../../style/text';
import { isMobileDevice } from '../../../style/utils';
import { colors } from '../../../tokens/colors/colors';
import { Size } from '../../../tokens/measurements/size';
import { EnrichedProductDiscoveryConversationMessage } from '../../../lib/productDiscovery/conversationMessages';
import { logUserEventUtil } from '../../../lib/events/userEvents';
import { ApolloClient } from '../../../api/graphql/client';

const arrowHeadUp = '/assets/images/icons/arrow-head-up-white.svg';

export function InputBar({
    inputText,
    setInputText,
    inputTextRef,
    setIsInputBarFocused,
    sendMessage,
    isAssistantThinking,
    setBottomPositionScrollButton,
    isLandingPage,
}: {
    inputText: string;
    setInputText: (text: string) => void;
    inputTextRef: React.RefObject<HTMLTextAreaElement>;
    setIsInputBarFocused: (isFocused: boolean) => void;
    sendMessage: (suggestedMessage?: string) => void;
    isAssistantThinking: boolean;
    setBottomPositionScrollButton: (value: number) => void;
    isLandingPage?: boolean;
}) {
    const inputBarContainerRef = React.useRef<HTMLDivElement | null>(null);
    const texts = getLocalizedTexts().productDiscovery;
    const [isSendButtonEnabled, setIsSendButtonEnabled] = React.useState(false);
    React.useEffect(() => {
        if (isAssistantThinking) setIsSendButtonEnabled(false);
        else setIsSendButtonEnabled(inputText.trim().length > 0);
    }, [inputText, isAssistantThinking]);
    useAdaptInputBarHeight({
        inputText,
        inputTextRef,
        inputBarContainerRef,
        setBottomPositionScrollButton,
    });
    return (
        <InputBarContainer ref={inputBarContainerRef} {...{ isMobileDevice, isLandingPage }}>
            <InputText
                ref={inputTextRef}
                rows={1}
                value={inputText}
                onChange={(event) => setInputText(event.target.value)}
                placeholder={texts.inputPlaceholder}
                onKeyDown={(event) => {
                    if (event.key === 'Enter' && !event.shiftKey) {
                        sendMessage();
                        if (isMobileDevice) inputTextRef.current?.blur();
                        event.preventDefault(); // Prevent the default behavior of adding a new line
                    }
                }}
                onFocus={() => setIsInputBarFocused(true)}
                // We use a timeout to ensure blur click events are processed before showing suggestions again, preventing accidental selection when the input loses focus
                onBlur={() => setTimeout(() => setIsInputBarFocused(false), 0)}
            />
            <SendButtonContainer
                {...{ isSendButtonEnabled }}
                onClick={() => {
                    if (isSendButtonEnabled) sendMessage();
                }}>
                <ArrowSendButtonImage src={arrowHeadUp} />
            </SendButtonContainer>
        </InputBarContainer>
    );
}

function useAdaptInputBarHeight({
    inputText,
    inputTextRef,
    inputBarContainerRef,
    setBottomPositionScrollButton,
}: {
    inputText: string;
    inputTextRef: React.RefObject<HTMLTextAreaElement>;
    inputBarContainerRef: React.RefObject<HTMLDivElement>;
    setBottomPositionScrollButton?: (value: number) => void;
}) {
    React.useEffect(() => {
        const textarea = inputTextRef.current;
        const inputBarContainer = inputBarContainerRef.current;
        if (!textarea || !inputBarContainer) return;
        const originalPlaceholder = textarea.placeholder;
        // We remove the placeholder to prevent the bar resizing when opening the feedback section
        textarea.placeholder = '';
        textarea.style.height = 'auto';
        const newTextAreaHeight = Math.min(
            textarea.scrollHeight,
            isMobileDevice
                ? INPUT_BAR_MAXIMUM_HEIGHT_MOBILE - INPUT_BAR_PADDING_MOBILE
                : INPUT_BAR_MAXIMUM_HEIGHT_DESKTOP - INPUT_BAR_PADDING_DESKTOP
        );
        textarea.style.height = `${newTextAreaHeight}px`;
        const inputBarContainerHeight = Math.max(
            newTextAreaHeight + (isMobileDevice ? INPUT_BAR_PADDING_MOBILE : INPUT_BAR_PADDING_DESKTOP),
            isMobileDevice ? INPUT_BAR_MINIMUM_HEIGHT_MOBILE : INPUT_BAR_MINIMUM_HEIGHT_DESKTOP
        );
        inputBarContainer.style.height = `${inputBarContainerHeight}px`;
        inputBarContainer.style.minHeight = `${inputBarContainerHeight}px`;
        setBottomPositionScrollButton?.(
            inputBarContainerHeight +
                (isMobileDevice
                    ? INPUT_BAR_MARGIN_BOTTOM_MOBILE + SPACE_BETWEEN_SCROLL_TO_BOTTOM_BUTTON_AND_INPUT_BAR_MOBILE
                    : INPUT_BAR_MARGIN_BOTTOM_DESKTOP + SPACE_BETWEEN_SCROLL_TO_BOTTOM_BUTTON_AND_INPUT_BAR_DESKTOP)
        );
        // We restore the placeholder value
        textarea.placeholder = originalPlaceholder;
    }, [inputText]);
}

export function FeedbackInputBar({
    inputText,
    setInputText,
    inputTextRef,
    setMessages,
    conversationId,
    apolloClient,
}: {
    inputText: string;
    setInputText: (text: string) => void;
    inputTextRef: React.RefObject<HTMLTextAreaElement>;
    setMessages: React.Dispatch<React.SetStateAction<EnrichedProductDiscoveryConversationMessage[]>>;
    conversationId: string;
    apolloClient: ApolloClient;
}) {
    const [isSendButtonEnabled, setIsSendButtonEnabled] = React.useState(false);
    const inputBarContainerRef = React.useRef<HTMLDivElement | null>(null);
    const texts = getLocalizedTexts().productDiscovery.feedback;
    useAdaptInputBarHeight({
        inputText,
        inputTextRef,
        inputBarContainerRef,
    });
    const sendFeedbackMessage = React.useCallback(() => {
        if (!isSendButtonEnabled || !inputText.trim()) return;
        setMessages((messages) => [
            ...messages,
            { role: 'user', content: inputText, messageIndex: 1, timestamp: Date.now() },
            { role: 'assistant', content: texts.defaultAnswer, messageIndex: 2, timestamp: Date.now() },
        ]);
        setInputText('');
        setIsSendButtonEnabled(false);
        logUserEventUtil(apolloClient, {
            type: 'sentFeedbackMessageOnJokoAi',
            payload: { conversationId, feedbackMessage: inputText },
        });
        inputTextRef.current?.setAttribute('disabled', 'true');
        inputTextRef.current?.setAttribute('placeholder', texts.thankYouButtonPlaceholder);
    }, [isSendButtonEnabled, inputText, setMessages, setInputText, apolloClient, conversationId, texts.defaultAnswer]);
    return (
        <InputBarContainer ref={inputBarContainerRef} isMobileDevice={isMobileDevice}>
            <InputText
                ref={inputTextRef}
                rows={1}
                value={inputText}
                onChange={(event) => {
                    setInputText(event.target.value);
                    setIsSendButtonEnabled(event.target.value.trim().length > 0);
                }}
                placeholder={texts.buttonPlaceholder}
                onKeyDown={(event) => {
                    if (event.key === 'Enter' && !event.shiftKey) {
                        event.preventDefault();
                        sendFeedbackMessage();
                    }
                }}
            />
            <SendButtonContainer {...{ isSendButtonEnabled }} onClick={sendFeedbackMessage}>
                <ArrowSendButtonImage src={arrowHeadUp} />
            </SendButtonContainer>
        </InputBarContainer>
    );
}

const INPUT_BAR_MARGIN_BOTTOM_DESKTOP = 24;
const INPUT_BAR_MARGIN_BOTTOM_MOBILE = 16;
const INPUT_BAR_MINIMUM_HEIGHT_DESKTOP = 48;
const INPUT_BAR_MINIMUM_HEIGHT_MOBILE = 44;
const INPUT_BAR_MAXIMUM_HEIGHT_DESKTOP = 142;
const INPUT_BAR_MAXIMUM_HEIGHT_MOBILE = 111;
const INPUT_BAR_PADDING_DESKTOP = 32;
const INPUT_BAR_PADDING_MOBILE = 16;
const SPACE_BETWEEN_SCROLL_TO_BOTTOM_BUTTON_AND_INPUT_BAR_DESKTOP = 16;
const SPACE_BETWEEN_SCROLL_TO_BOTTOM_BUTTON_AND_INPUT_BAR_MOBILE = 16;

type ProductDiscoveryContentWidthScale = '474px' | '630px' | '710px';

type ProductDiscoveryContentWidth = Partial<Record<Size, ProductDiscoveryContentWidthScale>>;

const productDiscoveryContentWidths: ProductDiscoveryContentWidth = {
    medium: '474px',
    large: '630px',
    xxLarge: '710px',
};

const InputBarContainer = styled.div<{ isMobileDevice: boolean; isLandingPage?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    min-height: ${INPUT_BAR_MINIMUM_HEIGHT_DESKTOP}px;
    max-height: 142px;
    max-width: calc(${productDiscoveryContentWidths.xxLarge} - 16px - 24px); // We need to remove the padding
    width: ${(isMobileDevice) => (isMobileDevice ? 'calc(100% - 64px)' : 'calc(100% - 32px)')};
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: ${({ isLandingPage }) => (isLandingPage ? INPUT_BAR_MARGIN_BOTTOM_DESKTOP : 0)}px;
    margin-top: ${({ isLandingPage }) => (isLandingPage ? 24 : 0)}px;
    border-radius: 12px;
    background-color: ${colors.background.light};

    @media (max-width: ${deviceWidthMeasurements.xLarge}) {
        max-width: calc(${productDiscoveryContentWidths.large} - 16px - 24px);
    }
    @media (max-width: ${deviceWidthMeasurements.medium}) {
        max-width: calc(${productDiscoveryContentWidths.medium} - 16px - 24px);
    }
    @media (max-width: ${deviceWidthMeasurements.small}) {
        height: ${INPUT_BAR_MINIMUM_HEIGHT_DESKTOP}px;
        min-height: ${INPUT_BAR_MINIMUM_HEIGHT_MOBILE}px;
        margin-bottom: ${({ isLandingPage }) => (isLandingPage ? INPUT_BAR_MARGIN_BOTTOM_MOBILE : 0)}px;
        padding-left: 12px;
        padding-right: 16px;
    }
`;

const SendButtonContainer = styled.div<{ isSendButtonEnabled: boolean }>`
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 96px;
    justify-content: center;
    align-items: center;
    background-color: ${({ isSendButtonEnabled }) =>
        isSendButtonEnabled ? colors.background.primary : colors.background.tertiary};
    ${CLICKABLE_ELEMENT_CSS_MIXIN}
`;

const ArrowSendButtonImage = styled.img`
    width: 16px;
    height: 16px;
    object-fit: contain;
`;

const InputText = styled.textarea`
    flex: 1;
    max-width: 100%;
    max-height: 110px;
    margin-right: 16px;
    padding: 0;
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    border: none;
    resize: none;
    background-color: transparent;
    scrollbar-color: ${colors.border.secondary} ${colors.background.light};

    &:focus {
        outline: none;
    }

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 16px;
        line-height: 19px;
    }
`;
