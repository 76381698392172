import React from 'react';
import styled, { keyframes } from 'styled-components';

export function SpinningWheel({ width, height }: { width: number; height: number }) {
    return <Wheel {...{ width, height }} />;
}

const spinnerAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Wheel = styled.div<{ width: number; height: number }>`
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    animation: ${spinnerAnimation} 0.8s linear infinite;
    margin: 0 auto;
`;

export function MoreResultsSpinningWheel({ size, borderSize = 4 }: { size: number; borderSize?: number }) {
    return <Ring size={size} borderSize={borderSize} />;
}

const Ring = styled.div<{ size: number; borderSize: number }>`
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border-radius: 50%;
    background: conic-gradient(black, transparent);
    position: relative;
    animation: ${spinnerAnimation} 1.2s linear infinite;

    &::before {
        content: '';
        position: absolute;
        top: ${({ borderSize }) => borderSize}px;
        left: ${({ borderSize }) => borderSize}px;
        width: calc(100% - ${({ borderSize }) => borderSize * 2}px);
        height: calc(100% - ${({ borderSize }) => borderSize * 2}px);
        background: white;
        border-radius: 50%;
    }
`;
